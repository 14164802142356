import React, { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import Input from "../../../../components/Input";
import { ReactComponent as CloseIcon } from "../../../../assets/img/clear.svg";
import { addUsertToLocationAxios, searchUserLocationAxios } from "../../../../api/place";
import {
  EditLocationStaffInterface,
  LocationInfoInterface,
  LocationUserInfoInterface,
  NewUserToLocationInterface,
  SearchLocationUserInterface
} from "../../../../types/place";
import InfoTooltip from "../../../../components/infoTooltip/infoTooltip";
import { ReactComponent as EditIcon } from "../../../../assets/img/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/img/delete.svg";
import Select from "../../../../components/Select";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getLightSpeedLocations } from "../../../../api/lightSpeed";

interface IProps {
  setLocationStaffSidebarData: (arg: EditLocationStaffInterface | null) => void;
  setShowLocationStaffSidebar: (arg: boolean) => void;
  setDeleteStaffObj: (arg: LocationUserInfoInterface | null) => void;
  getPlaceInfo: () => void;
  location: LocationInfoInterface;
  locations: Array<LocationInfoInterface>;
  isShow?: boolean;
}

const StaffMembers: FC<IProps> = ({
                                    setLocationStaffSidebarData,
                                    location,
                                    locations,
                                    setShowLocationStaffSidebar,
                                    setDeleteStaffObj,
                                    getPlaceInfo
                                  }) => {
  let timeout: any;

  const [searchLocationUserLoading, setSearchLocationUserLoading] =
    React.useState<{ status: boolean; location_id: number | null }>({
      status: false,
      location_id: null
    });

  const { loggedUser } = useTypedSelector((state) => state.auth);

  const [createItems, setCreateItems] = useState([{ label: "User", value: 0 }, { label: "POS.ID", value: 1 }]);
  const [showHideform, setShowHideForm] = useState(createItems[0].value);
  const [lightSpeedSelect, setLightSpeedSelect] = useState([
    {
      value: "",
      label: "All LightSpeed",
    },
  ])
  const [lightSpeedSelected, setLightSpeedSelected] = useState<{
    value: string | number;
    label: string;
  }>({
      value: '',
      label: '',
    }
  )


  const [formAddUserToLocationLoading, setFormAddUserToLocationLoading] =
    React.useState<boolean>(false);

  const [
    formAddUsertToLocationErrorMessage,
    setFormAddUsertToLocationErrorMessage
  ] = React.useState<string>("");

  const [newUserToLocation, setNewUserToLocation] =
    React.useState<NewUserToLocationInterface | null>();

  useEffect(() => {
    if (loggedUser.user.allow_lightspeed) {
      setCreateItems([...createItems, { label: "Lightspeed", value: 2 }]);
      getLightSpeedLocations().then((res) => {
        if(res.data.status){
          setLightSpeedSelect(res.data.outlets)
          setLightSpeedSelected({
            value: res.data.outlets[0].value,
            label: res.data.outlets[0].label,
          })
        }
        
      })
    };
  }, []);


  const addNewUserToLocation = (location_id: number) => {
    setNewUserToLocation((prevState: any) => {
      return { ...prevState, id: location_id };
    });
  };

  const setValueNewUserToLocation = (name: string, value: string) => {
    if (formAddUsertToLocationErrorMessage) {
      setFormAddUsertToLocationErrorMessage("");
    }
    setNewUserToLocation((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSearchLocationUser = (searchObj: SearchLocationUserInterface) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      setSearchLocationUserLoading({
        status: true,
        location_id: searchObj.location_id
      });

      searchUserLocationAxios(searchObj)
        .then((response) => {
          const newLocations = [];

          locations.forEach((currentValue) => {
            if (currentValue.id === searchObj.location_id) {
              const newCurrentValue = currentValue;

              newCurrentValue.users = response.data.users;
              newLocations.push(currentValue);
            } else {
              newLocations.push(currentValue);
            }
          });

          setSearchLocationUserLoading({
            status: false,
            location_id: null
          });
        })
        .catch(() => {
          setSearchLocationUserLoading({
            status: false,
            location_id: null
          });
        });
    }, 500);
  };

  const editLocationStaffOpenSidebar = (staff: LocationUserInfoInterface) => {
    if (staff.is_pos) {
      setLocationStaffSidebarData({
        seller_id: staff.id,
        first_name: staff.first_name,
        posId: staff.posId,
        is_pos: staff.is_pos,
        password: staff.password
      });
    } else if (staff.is_lightspeed_pos) {
      setLocationStaffSidebarData({
        seller_id: staff.id,
        first_name: staff.first_name,
        posId: staff.lightspeedPosId,
        is_lightspeed_pos: staff.is_lightspeed_pos,
        password: staff.password
      });
    }else {
      setLocationStaffSidebarData({
        seller_id: staff.id,
        first_name: staff.first_name,
        last_name: staff.last_name,
        password: staff.password
      });
    }

    setShowLocationStaffSidebar(true);
  };

  const deleteLocationStaffAsk = (staff: LocationUserInfoInterface) => {
    setDeleteStaffObj(staff);
  };

  const locationStaffTableColumns = [
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: LocationUserInfoInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      }
    },
    {
      name: "Email",
      minWidth: "350px",
      cell: (row: LocationUserInfoInterface): JSX.Element => {
        return <div>{row.username}</div>;
      }
    },
    {
      name: (
        <InfoTooltip
          infoText="Share the user credentials with your staff at the above location. They can log into the Lynked App and scan customer QR codes immediately" />
      ),
      minWidth: "100px",
      cell: (row: LocationUserInfoInterface): JSX.Element => {
        return (
          <div className="action">
            <button
              type="button"
              className="edit"
              onClick={() => editLocationStaffOpenSidebar(row)}
            >
              <EditIcon />
            </button>
            <button
              type="button"
              className="delete"
              onClick={() => deleteLocationStaffAsk(row)}
            >
              <DeleteIcon />
            </button>
          </div>
        );
      }
    }
  ];

  const handleAddUserToLocation = (event: React.FormEvent) => {
    event.preventDefault();
    if (newUserToLocation) {
      setFormAddUserToLocationLoading(true);

      addUsertToLocationAxios(showHideform === 0 ? newUserToLocation : showHideform === 1 ? { ...newUserToLocation, type: showHideform } : { ...newUserToLocation, type: showHideform, lightSpeedId: `${lightSpeedSelected.value}`, lightSpeedName: `${lightSpeedSelected.label}`})
        .then(() => {
          getPlaceInfo();
          setNewUserToLocation(null);
          setFormAddUserToLocationLoading(false);
        })
        .catch((error) => {
          setFormAddUsertToLocationErrorMessage(
            error?.data?.message
              ? error?.data?.message
              : error?.data?.detail
                ? error?.data?.detail
                : "Internal Server Error"
          );

          setFormAddUserToLocationLoading(false);
        });
    }
  };

  return (
    <div className="staff-list-wrap">
      <div className="create-filter">
        <button
          type="button"
          className="btn-white"
          onClick={() => addNewUserToLocation(location.id)}
        >
          Add New User
        </button>
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            getValue={(value) =>
              handleSearchLocationUser({
                location_id: location.id,
                search: value
              })
            }
          />
        </div>
      </div>
      {newUserToLocation?.id === location.id ? (
        <div className="create-new-user">
          {loggedUser.user.allow_pos || loggedUser.user.allow_lightspeed ? <div className="groups-wrap">
            <Select
              placeholder="What you want create"
              selectorList={createItems}
              positionForShow="bottom"
              defaultValue={createItems[0]}
              required
              getValue={(value) =>
                setShowHideForm(+value)
              }
              hideSelectorFilter
            />
          </div> : null}

          {
            showHideform === 0 ? (
              <form
                onSubmit={handleAddUserToLocation}
                className={`${
                  formAddUserToLocationLoading ? "loading-blue" : ""
                }`}
                autoComplete="off"

              >
                <div className="loading-innerwrap">
                  <div className="title-close">
                    <div className="title">Create new user</div>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setNewUserToLocation(null)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="input-wrap">
                    <div className="first_name-group">
                      <Input
                        placeholder="First name"
                        required
                        autoComplete="off"
                        getValue={(value) =>
                          setValueNewUserToLocation("first_name", value)
                        }
                      />
                    </div>
                    <div className="last_name-group">
                      <Input
                        placeholder="Last name"
                        required
                        autoComplete="off"
                        getValue={(value) =>
                          setValueNewUserToLocation("last_name", value)
                        }
                      />
                    </div>
                    <div className="password-group">
                      <Input
                        type="password"
                        placeholder="Password"
                        required
                        autoComplete="new-password"
                        getValue={(value) =>
                          setValueNewUserToLocation("password", value)
                        }
                      />
                    </div>
                  </div>
                  {formAddUsertToLocationErrorMessage && (
                    <div className="form-error-message">
                      {formAddUsertToLocationErrorMessage}
                    </div>
                  )}
                  <button type="submit" className="btn-blue">
                    Add User
                  </button>
                </div>
              </form>
            ) : showHideform === 1 ? (
              <form
                onSubmit={handleAddUserToLocation}
                className={`${
                  formAddUserToLocationLoading ? "loading-blue" : ""
                }`}
                autoComplete="off"

              >
                <input role="presentation" autoComplete="none" name="hidden" type="text" style={{ display: "none" }} />
                <div className="loading-innerwrap">
                  <div className="title-close">
                    <div className="title">Create new POS.ID</div>
                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        setNewUserToLocation(null);
                        setShowHideForm(0);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="input-wrap">
                    <div className="first_name-group">
                      <Input
                        placeholder="Name"
                        required
                        autoComplete="off"
                        getValue={(value) =>
                          setValueNewUserToLocation("first_name", value)
                        }
                      />
                    </div>
                    <div className="last_name-group">
                      <Input
                        placeholder="Pos Id"
                        required
                        autoComplete="off"
                        getValue={(value) =>
                          setValueNewUserToLocation("posId", value)
                        }
                      />
                    </div>
                    <div className="password-group">
                      <Input
                        type="password"
                        placeholder="Password"
                        required
                        autoComplete="new-password"
                        getValue={(value) =>
                          setValueNewUserToLocation("password", value)
                        }
                      />
                    </div>
                  </div>
                  {/*{formAddUsertToLocationErrorMessage && (*/}
                  {/*  <div className="form-error-message">*/}
                  {/*    {formAddUsertToLocationErrorMessage}*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <button type="submit" className="btn-blue">
                    Add POS.ID
                  </button>
                </div>
              </form>
            ) : (
              <form
                onSubmit={handleAddUserToLocation}
                className={`${
                  formAddUserToLocationLoading ? "loading-blue" : ""
                }`}
                autoComplete="off"

              >
                <input role="presentation" autoComplete="none" name="hidden" type="text" style={{ display: "none" }} />
                <div className="loading-innerwrap">
                  <div className="title-close">
                    <div className="title">Create new Lightspeed</div>
                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        setNewUserToLocation(null);
                        setShowHideForm(0);
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="input-wrap">
                    <div className="first_name-group">
                      <Input
                        placeholder="Name"
                        required
                        autoComplete="off"
                        getValue={(value) =>
                          setValueNewUserToLocation("first_name", value)
                        }
                      />
                    </div>
                    <div className="last_name-group">
                      <Select selectorList={lightSpeedSelect}
                              positionForShow="bottom"
                              defaultValue={lightSpeedSelected}
                              getValue={(value, label) => {
                                setLightSpeedSelected({
                                  value: `${value}`,
                                  label: `${label}`
                                })
                                setValueNewUserToLocation("lightSpeedId", `${value}`)
                                setValueNewUserToLocation("lightSpeedName", `${label}`)
                              }}
                              required
                              hideSelectorFilter />
                      {/*<Input*/}
                      {/*  placeholder="Light speed Id"*/}
                      {/*  required*/}
                      {/*  autoComplete="off"*/}
                      {/*  getValue={(value) =>*/}
                      {/*    setValueNewUserToLocation("lightSpeedId", value)*/}
                      {/*  }*/}
                      {/*/>*/}
                    </div>
                    <div className="password-group">
                      <Input
                        type="password"
                        placeholder="Password"
                        required
                        autoComplete="new-password"
                        getValue={(value) =>
                          setValueNewUserToLocation("password", value)
                        }
                      />
                    </div>
                  </div>
                  {/*{formAddUsertToLocationErrorMessage && (*/}
                  {/*  <div className="form-error-message">*/}
                  {/*    {formAddUsertToLocationErrorMessage}*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <button type="submit" className="btn-blue">
                    Add Lightspeed
                  </button>
                </div>
              </form>
            )
          }

        </div>
      ) : null}
      <div
        className={`staff-list-table-wrap ${
          searchLocationUserLoading.status &&
          searchLocationUserLoading.location_id === location.id
            ? "loading-blue"
            : ""
        }`}
      >
        <div className="loading-innerwrap">
          {location.users.length > 0 ? (
            <div className="staff-list table">
              <DataTable
                noHeader
                columns={locationStaffTableColumns}
                data={location.users}
              />
            </div>
          ) : (
            <div className="nothing-found">Sorry, nothing found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffMembers;
