import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import Input from "../../components/Input";
import {ReactComponent as SearchIcon} from "../../assets/img/search.svg";
import {ReactComponent as EditIcon} from "../../assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";
import {CustomerInfoInterface, CustomerRequestInterface} from "../../types/customer";
import DataTable from "react-data-table-component";
import Pagination from "../../components/Pagination";
import Calendar from "../../components/Calendar";
import {ProductDiscountItem} from "../../types/productDiscountTypes";
import moment from "moment";
import {
  createProductDiscountAxios,
  getAllProductDiscountAxios,
  removeProductDiscountAxios
} from "../../api/productDiscount";

const ProductDiscount = () => {
  const [productList, setProductList] = useState<ProductDiscountItem[]>([]);
  const [productListInfo, setProductListInfo] = useState<CustomerInfoInterface>({
    count_users: 0,
    limit: 0,
    page: 0,
    total_pages_count: 0,
    total_users_count: 0,
  });
  const [requestParamtrs, setRequestParamtrs] =
    useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
    });
  const [isLoading, setIsLoading] = useState(false);
  
  const changeProductParameters = (id: number, columnName: string, value: Date | string | boolean | number| {
    value: string,
    label: string
  }) => {
    productList[id] = {...productList[id], [columnName]: value}
    setProductList([...productList])
  }
  
  const productDiscountTableColumns = [
    {
      name: "Product Name",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Product Name"
                required
                defaultValue={row.name}
                autoComplete="off"
                onInput={e=>row.name = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.name
            )}
          </div>
        );
      },
    },
    {
      name: "Product Group",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Product Group"
                required
                defaultValue={row.group}
                autoComplete="off"
                onInput={e=>row.group = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.group
            )}
          </div>
        );
      },
    },
    {
      name: "SKU",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="SKU"
                required
                defaultValue={row.sku}
                autoComplete="off"
                onInput={e=>row.sku = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.sku
            )}
          </div>
        );
      },
    },
    {
      name: "Discount percent",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Discount percent"
                required
                type={"number"}
                min={0}
                max={100}
                onInput={e=> +e.currentTarget.value <= 0 ? row.discount = 0 : +e.currentTarget.value >= 100 ? row.discount = 100 : row.discount = e.currentTarget.value}
                defaultValue={row.discount}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.discount
            )}
          </div>
        );
      },
    },
    {
      name: "Start Date",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <Calendar
                selected={new Date(row.start_date)}
                onChange={(e) => e >= new Date() ? changeProductParameters(index, 'start_date', e.getTime()) : changeProductParameters(index, 'start_date', Date.now())}
                dateFormat="MMMM d, yyyy"
                timeSelect={false}
                label={""}
              />
            ) : (
              `${moment(row.start_date).format('MMM D, yyyy')}`
            )}
          </div>
        );
      },
    },
    {
      name: "End Date",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <Calendar
                selected={new Date(row.end_date)}
                onChange={(e) => e >= new Date() ? changeProductParameters(index, 'end_date', e.getTime()) : changeProductParameters(index, 'end_date', Date.now())}
                dateFormat="MMMM d, yyyy"
                timeSelect={false}
                label={""}
              />
            ) : (
              `${moment(row.end_date).format('MMM D, yyyy')}`
            )}
          </div>
        );
      },
    },
    {
      name: "",
      minWidth: "10%",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => setEditItem(row.id)}
                >
                  <EditIcon/>
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => removeProductDiscount(row.id)}
                >
                  <DeleteIcon/>
                </button>
              </>
            ) : (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => createProductDiscount(row)}
              >
                Save
              </button>
            )}
          </div>
        );
      },
    },
  ];
  
  function addStaffMembers() {
    const newProductItem: ProductDiscountItem = {
      edit: true,
      name: '',
      group: '',
      sku: '',
      discount: '',
      start_date: Date.now(),
      end_date:  Date.now()
    }
    setProductList([...productList, newProductItem])
  }
  const getProductDiscounts = () => {
    setIsLoading(true)
    getAllProductDiscountAxios(requestParamtrs).then(res=>{
      setProductList(res.productsDiscount)
      setProductListInfo({
        count_users: Number(res.count_product_discounts),
        limit: Number(res.limit),
        page: Number(res.page),
        total_pages_count: Number(res.total_pages_count),
        total_users_count: Number(res.total_product_discounts_count),
      });
    }).finally(()=>{
      setIsLoading(false)
    })
  }
  
  const createProductDiscount = (data: ProductDiscountItem) => {
    setIsLoading(true)
    const requestData = {...data, start_date: moment(data.start_date).format('yyyy-MM-DD HH:mm:ss'), end_date: moment(data.end_date).format('yyyy-MM-DD HH:mm:ss')}
    createProductDiscountAxios(requestData).then(res=>{
      getProductDiscounts()
    }).finally(()=>{
      setIsLoading(false)
    })
  }
  
  const setEditItem = (id: number) =>{
    setProductList(productList.map(item=>item.id === id ? item = {...item, edit: true} : item))
  }
  
  const removeProductDiscount = (id: number) => {
    setIsLoading(true)
    removeProductDiscountAxios({id}).then(res=>{
      getProductDiscounts()
    }).finally(()=>{
      setIsLoading(false)
    })
  }
  
  useEffect(() => {
    getProductDiscounts()
  }, [requestParamtrs]);
  
  return (
    <Wrapper>
      <div className="page-title">Product discounts</div>
      <div className="top-bar staff-top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) =>
              setRequestParamtrs({...requestParamtrs, search: value})
            }
          />
        </div>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{marginBottom: "12px", marginRight: "20px"}}
          onClick={() => addStaffMembers()}
        >
          Add New Product
        </button>
      
      </div>
      <div className={`table-wrap ${isLoading ? "loading-blue" : ""}`}>
        <div className="table">
          <DataTable
            noHeader
            columns={productDiscountTableColumns}
            data={productList}
            // expandableRows
            // expandableRowsComponent={
            //   <StaffFamilyTable data={staffList} getCustomerList={getStaff}/>
            // }
          />
          <Pagination
            {...productListInfo}
            requestLimit={requestParamtrs.limit}
            setRequestParamtrs={setRequestParamtrs}
          />
        </div>
      </div>
    
    </Wrapper>
  );
};

export default ProductDiscount;
