import React from "react";

const FormError: React.FC<{
  formErrorMessages: any;
  field: string;
}> = ({ formErrorMessages, field }) => {
  return (
    <>
      {formErrorMessages && formErrorMessages[field] ? (
        <div className="form-error-message">{formErrorMessages[field]}</div>
      ) : null}
    </>
  );
};

export default FormError;
