import React, {useState} from 'react';
import DataTable from "react-data-table-component";
import { ReactComponent as VisibilityIcon } from "src/assets/img/visibility.svg";
import TransactionInformation from "../../../Customers/CustomerList/TransactionInformation/TransactionInformation";
import {WrapperTableList} from "../../../Customers/CustomerList/EditableTable/styled";
import userDefaultImg from "../../../../assets/img/default-user.jpg";
import {getAllUserTransactionBasketForBusinessPlaceAxios} from "../../../../api/adminPanel";
import {format} from "date-fns";
import {CustomerUserInterface} from "../../../../types/customer";

interface IUserTransactionList{
  transactions: any[],
  promotion: any,
  store: string
}

const UserTransactionList = ({transactions, promotion, store}:IUserTransactionList) => {
  const type = promotion === "Stamp" ? "Stamps" : "Points";
  const customerTableColumns = [
    {
      name: "Icon",
      width: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="photo">
            {row?.user?.photo ? (
              <img src={row.user.image} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.user.full_name}
          </div>
        );
      }
    },
    {
      name: "Store",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{store || "-"}</div>;
      }
    },
    {
      name: "POS.I.D",
      cell: (row: any): JSX.Element => {
        return <div>{row.posId || "-"}</div>;
      }
    },
    {
      name: "POS User .I.D",
      cell: (row: any): JSX.Element => {
        return <div>{row.posUserId || "-"}</div>;
      }
    },
    {
      name: "Receipt No.",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.receipt || "-"}</div>;
      }
    },
    {
      name: "Time",
      minWidth: "120px",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return (
          <div>
            {row.date
              ? format(new Date(row.date), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    },
    {
      name: "Basket",
      maxWidth: "75px",
      right: true,
      cell: (row: any, i: number): JSX.Element => {
        return (
          <>
            <div className={"show-order"} data-tag="allowRowEvents">
              <VisibilityIcon data-tag="allowRowEvents" className={"visibility-icon"} />
            </div>

          </>
        );
      }
    }
  ];
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});
  const [listRow, setListRow] = useState<any>({});
  
  const getBasket = (id:number) =>{
    getAllUserTransactionBasketForBusinessPlaceAxios({id}).then(res => {
      setCustomerInformation(res.data.basket)
      setCustomer({used_points: res.data.used_points, used_vouchers: res.data.used_vouchers, points: res.data.points})
    })
  }
  return (
    <WrapperTableList>
      {!!transactions &&
          <>
              <DataTable
                  data={transactions}
                  columns={customerTableColumns}
                  noHeader
                  onRowClicked={(row: any) => {
                    setShowTransactionDetail(true);
                    getBasket(row.id)
                    setListRow(row)
                  }}
                  expandableRows
                  expandableRowsHideExpander={true}
                  expandableRowExpanded={(row) => (row === listRow)}
                  expandOnRowClicked={true}
                  expandableRowsComponent={
                    <TransactionInformation show={showTransactionDetail} customerInformation={customerInformation}
                                            productList={customerInformation.productsList} customer={customer}
                                            setShow={setShowTransactionDetail} />
                  }
              />
      
          </>
      }
    </WrapperTableList>
  );
};

export default UserTransactionList;
