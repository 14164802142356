import {
  RequestParamtrsInterface,
  SearchLocationUserInterface,
  NewUserToLocationInterface,
  EditLocationStaffInterface,
} from "src/types/place";
import axios from "./api";
import { RequestFormData } from "../utils/requestFormData";

export const getPlaceInfoAxios = (): Promise<any> =>
  axios
    .get("/api/getPlace")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updatePlacePhotoAxios = (
  requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/api/updatePlacePhoto", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateBusinessInfoAxios = (
  requestParameters: RequestParamtrsInterface
): Promise<any> => {
  const formData = new RequestFormData();

  formData.append("business_category", requestParameters.business_category);
  formData.append("business_name", requestParameters.business_name);
  formData.append("businessType", requestParameters.businessType);
  formData.append("description", requestParameters.description);
  formData.append("max_count", requestParameters.max_count.toString());
  formData.append("promotion_label", requestParameters.promotion_label ?? "");
  formData.append("promotion_name", requestParameters.promotion_name ?? "");
  formData.append(
    "promotion_type",
    requestParameters.promotion_type.toString()
  );
  formData.append("website_url", requestParameters.website_url ?? "");
  formData.append("defaultVoucherName", requestParameters.defaultVoucherName ?? "");
  formData.append("instagram_url", requestParameters.instagram_url ?? "");
  formData.append("facebook_url", requestParameters.facebook_url ?? "");
  formData.appendArray("points", requestParameters.points);
  formData.append("first_checkIn", requestParameters.first_checkIn ?? "");
  return axios
    .post("/api/updatePlace", formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const addLocationAxios = (
  requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/api/addNewLocationToPlace", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateLocationAxios = (
  requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/api/updateLocation", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeLocationAxios = (id: number): Promise<any> =>
  axios
    .post("/api/removeLocation", { location_id: id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const searchUserLocationAxios = (
  requestParamtrs: SearchLocationUserInterface
): Promise<any> =>
  axios
    .post("/api/sellerListSearch", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const addUsertToLocationAxios = (
  requestParamtrs: NewUserToLocationInterface
): Promise<any> =>
  axios
    .post("/api/addUsertToLocation", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateLocationStaffAxios = (
  requestParamtrs: EditLocationStaffInterface
): Promise<any> =>
  axios
    .post("/api/updateSellerInfo", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeLocationStaffAxios = (id: number): Promise<any> =>
  axios
    .post("/api/removeStaff", { id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getPlaceInfoByHashAxios = (hash: string): Promise<any> =>
  axios
    .post("/public/getPlaceByHash", { hash })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const confirmPlaceInfoByHashAxios = (hash: string): Promise<any> =>
  axios
    .post("/public/confirmPlaceByHash", { hash })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const declinePlaceInfoByHashAxios = (hash: string): Promise<any> =>
  axios
    .post("/public/declinePlaceByHash", { hash })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
