import React, {useEffect, useState} from 'react';
import {Wrapper} from './styled'
import AdminPartnersItem from "../../../components/AdminPartnersItem";
import {AdvertsPartnerType} from "../../../types/adminTypes";
import {getAdvertPartnersAxios} from "../../../api/adminPanel";

const AdminAdverts = () => {
  const [partners, setPartners] = useState<AdvertsPartnerType[]>([])
  useEffect(() => {
    handlerGetPartner()
  }, []);
  
  const handlerGetPartner = () => {
    getAdvertPartnersAxios().then(({data}) => {
      let dataPartner: AdvertsPartnerType[] = []
      data.advertPartners.forEach((partner: any) => {
        dataPartner.push({name: +partner.business.id, id: partner.id, logo: partner.logoUrl})
      })
      
      setPartners([...dataPartner])
    })
  }
  return (
    <Wrapper>
      <div className="header">
        <h2>In-App Advertisements</h2>
      </div>
      <div className="main_advert">
        <div className="subtitle">
          <h3>Featured Partners</h3>
        </div>
        <AdminPartnersItem setPartners={setPartners} type={'partner'} advertList={partners} offers={false}
                           getAllPartners={handlerGetPartner}/>
      </div>
    </Wrapper>
  );
};

export default AdminAdverts;
