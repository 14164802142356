import { Color } from "src/types/color";
import styled, { keyframes } from "styled-components";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

const Wrapper = styled.div`
  .card-preview {
    overflow: hidden;
    width: 335px;
    border-radius: 15px;
    background-color: ${Color.white};
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    padding: 25px;
    padding-bottom: 0;
    box-sizing: border-box;

    .top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;

      .logo {
        line-height: 0;
        margin-right: 15px;

        img {
          object-fit: cover;
          height: 60px;
          width: 60px;
          border-radius: 100%;
        }
      }

      .right {
        width: calc(100% - 75px);
      }

      .name-notificationCount {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;

        .name {
          font-family: "Averta Std ExtraBold";
          font-size: 16px;
          line-height: 19px;
          color: #2f3640;
        }

        .notification-count {
          display: flex;
          align-items: center;

          .notification {
            margin-right: 15px;
          }

          .count {
            border: 1px solid ${Color.blue};
            border-radius: 4px;
            background-color: ${Color.white};
            padding: 0 5px;
            box-sizing: border-box;
            color: ${Color.blue};
            font-size: 12px;
            font-weight: 300;
            line-height: 21px;
          }

          @media (max-width: 400px) {
            flex-wrap: wrap;
            .notification {
              flex-basis: 100%;
              margin-right: 0px;
            }

            .count {
              flex-basis: 100%;
            }
          }
        }
      }

      .info {
        font-family: "Averta Std Semibold";

        .title {
          color: #bcbcbc;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          margin-right: 3px;
        }

        .content {
          color: ${Color.grey};
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }

    .promotion-count {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .promotion-item {
        width: 49px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }

        .promotion-item-icon {
          margin: auto;
          width: max-content;
        }

        .promotion-item-icon-number {
          font-family: "Averta Std ExtraBold";
          color: #ffffff;
          position: absolute;
          top: -1px;
          left: -3px;
          font-size: 9px;
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          text-align: center;
          line-height: 49px;
        }
      }

      .promotion_done {
        width: 49px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .points-progress-line {
        width: 100%;
        display: flex;

        .progress-line {
          width: calc(100% - 85px);
          margin-right: 10px;
          border-radius: 4px;
          color: ${Color.white};
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          padding: 8px 15px;
          /* &.full { */
          background: #5771e1;
          /* } */
            /* &.empty {
            background-color: #dcdef7;
            color: ${Color.blueDarkText};
          } */
        }

        .progress-done {
          position: relative;
          line-height: 0;
          width: 75px;

          .text {
            position: absolute;
            top: 7px;
            left: 5px;
            color: ${Color.white};
            font-size: 10px;
            font-weight: 300;
            line-height: 11px;
            width: 45px;
            word-wrap: break-word;
          }

          .dark-text {
            color: ${Color.blueDarkText};
          }
        }
      }
    }

    &.full {
      .promotion-count {
        .promotion-item {
          svg {
            g {
              opacity: 1;
            }

            path {
              fill: ${Color.blue};
            }
          }
        }
      }
    }

    @media (max-width: 400px) {
      padding: 20px 10px;
    }
  }
  .card-bottom {
    height: 45px;
    background: linear-gradient(
      0deg,
      ${Color.gradientFreeCardFrom} -16.46%,
      ${Color.gradientFreeCardTo} 98.11%
    );
    margin-left: -25px;
    margin-right: -25px;
    position: relative;
    .card-bottom-control-button {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      border: 3px solid white;
      top: 0px;
      left: calc(50% - 13px);
      padding: 0;
    }
    .bottom-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 15px;
      border-radius: 0px 0px 15px 15px;
      background-color: white;
    }
  }

  .card-closed {
    margin-bottom: 0;
    max-height: 1px;
    overflow: hidden;
    transition: max-height 0.5s ease-in, margin-bottom 0.3s ease-out;
  }

  .card-opened {
    margin-bottom: 14px;
    max-height: 150px;
    transition: max-height 0.5s ease-in, margin-bottom 0.2s ease-out;
  }

  .card-bottom-control-button:hover {
    transition: transform 0.8s ease-in-out;
    background: #ec9345;
  }

  .card-button-closed {
    .card-bottom-control-button {
      transition: transform 0.8s ease-in-out;
      transform: rotate(180deg);
    }
  }

  .card-button-opened {
    .card-bottom-control-button {
      transform: rotate(360deg);
    }
  }

  .page-title {
    margin-bottom: 12px;
  }

  .page-subtitle {
    max-width: 515px;
    margin-bottom: 30px;
  }

  .main-content {
    position: relative;
    min-height: 350px;

    &.loading-blue::before {
      display: none;
    }
  }

  .business-info-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .form_wrapper {
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    @media (max-width: 500px) {
      padding: 20px;
    }
  }

  .business-info-left {
    position: relative;
    width: calc(65% - 15px);
      /* border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px; */
    margin-right: 15px;
    box-sizing: border-box;

    .form-group {
      margin-bottom: 30px;

      .select {
        margin-bottom: 0;
      }

      &_three_items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
          width: 32% ;
        }
        @media (max-width: 700px) {
          & > * {
          flex-basis: 100%;
        }
        }
      }
    }

    .category-promotionName {
      display: flex;
      flex-wrap: wrap;
    }

    .category-group {
      width: calc(50% - 15px);
      margin-right: 15px;
    }

    .promotionName-group {
      width: calc(50% - 15px);
      margin-left: 15px;
    }

    .promotionType-promotionCount {
      display: flex;
      flex-wrap: wrap;

      &.Points {
        flex-wrap: nowrap;

        & > div {
          width: calc(100% / 3);

          &.promotionVaucherInformation-group {
            margin-right: 15px;
            margin-left: 15px;
          }
        }
      }
    }

    .promotionType-group {
      width: calc(50% - 15px);
      margin-right: 15px;
    }

    .promotionCount-group {
      width: calc(50% - 15px);
      margin-left: 15px;
    }

    .line {
      height: 1px;
      background-color: rgba(0, 13, 70, 0.1);
      margin-bottom: 10px;
    }

    .form-error-message {
      color: red;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 15px;
    }

    .button-row {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      .verification-status {
        margin-right: 20px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: rgb(0, 13, 70);
        border: 1.5px solid rgb(0, 13, 70);
        border-radius: 4px;
        background-color: ${Color.white};

        &.approved {
          border: 1.5px solid ${Color.blue};
          color: ${Color.blue};
        }
      }

      .btn-blue {
        width: auto;
        padding: 10px 30px;
      }
    }
  }

  .business-info-right_wrapper {
    width: 35%;
    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  .business-info-right {
    max-width: 395px;
    margin: 0 auto;
    background-color: ${Color.white};
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    min-height: 100%;
    position: relative;
  }

  .dropZone {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.with-photo {
      & > div {
        .dropZone-content-text {
          opacity: 0;
          transition: 0.5s all ease;
        }
      }

      &:hover {
        & > div {
          .dropZone-content-text {
            opacity: 0.9;
          }
        }
      }
    }

    & > div {
      position: relative;
      height: 100%;
      outline: none;
      cursor: pointer;

      &.active_true {
        border-color: ${Color.blueDark};
        box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
      }

      &.active_false {
        min-height: 320px;
      }

      input {
        max-width: 100%;
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        opacity: 0;
        z-index: -1;
      }

      .img {
        line-height: 0;

        img {
          height: 320px;
          width: 100%;
          object-fit: cover;
        }
      }

      .dropZone-content-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${Color.blueLightBackground};
        border: 1px dashed ${Color.blue};
        border-radius: 4px;
        outline: none;
        padding: 30px;
        cursor: pointer;
        transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

        & > svg {
          margin-bottom: 5px;
          animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

          path[fill-rule="evenodd"] {
            fill: ${Color.blue};
          }
        }

        .text {
          color: ${Color.blue};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          span {
            margin-bottom: 5px;
          }

          .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          .description {
            opacity: 0.6;
            font-size: 12px;
            font-weight: 300;
            line-height: 15px;
          }

          .file-name {
            font-size: 21px;
            font-weight: 300;
            line-height: 23px;
          }
        }
      }
    }
  }

  .logo-wrap {
    .info-sixe {
      margin-top: 15px;
      border-radius: 4px;
      background-color: rgba(81, 92, 221, 0.1);
      padding: 10px 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 12px;

        path.circle {
          fill: ${Color.blue};
        }
      }

      .text {
        color: ${Color.blue};
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }

  .locations-card-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .locations {
    position: relative;
    /* margin-right: 30px; */
    /* width: calc(70% - 30px); */
    margin-top: 30px;
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 30px;
    box-sizing: border-box;

    .title {
      color: ${Color.blueDarkText};
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 25px;
    }

    .create-filter {
      margin-bottom: 15px;
    }

    .create-location {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        position: relative;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .select-deal-container {
        .select {
          margin-bottom: 15px;
        }
      }

      .input-wrap {
        margin-bottom: 15px;

        .location-group {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .add-location-wrapper {
        display: flex;
        flex-direction: column;

        .left-block {
          width: 100%;
          margin-right: 15px;
        }

        .right-block {
          width: 100%;

          .upload-image {
            height: 150px;
            margin-bottom: 15px;
          }
        }
      }
    }

    .create-new-user {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        overflow: hidden;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .input-wrap {
        display: flex;
        margin-bottom: 15px;

        & > div {
          flex: 1;
          width: calc(100% / 3);
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .location-item {
      border-radius: 4px;
      background-color: ${Color.blueLightBackground};
      margin-bottom: 25px;
      padding: 20px 30px;
      box-sizing: border-box;

      .main {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        margin-bottom: 15px;

        .logo-nameAddress {
          display: flex;
          align-items: flex-start;
        }

        .logo {
          line-height: 0;
          margin-right: 15px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 100%;
            object-fit: cover;
          }
        }

        .name-address {
          width: calc(100% - 55px);
        }

        .name {
          color: ${Color.blueDarkText};
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          margin-bottom: 5px;
        }

        .address {
          opacity: 0.6;
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          margin-bottom: 20px;
        }

        .edit-remove {
          display: flex;
          align-items: center;

          button {
            background: none;
            padding: 0;
            opacity: 0.6;

            &:hover {
              opacity: 1;
            }
          }

          .edit {
            margin-right: 10px;
          }
        }
      }

      .two_btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 600px) {
          * {
              flex-basis: 100%;
              margin-bottom: 10px;
            }
          }
        button {
          background: none;
          color: ${Color.blue};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
        }
      }

      &.hide-staff {
        .show-hide-staff {
          button {
            .hide {
              display: none;
            }
          }
        }

        .staff-list-wrap {
          height: 0px;
          overflow: hidden;
        }
      }

      &.show-staff {
        .show-hide-staff {
          button {
            .show {
              display: none;
            }
          }
        }
      }

      .show-hide-staff {
        text-align: center;
      }

      .staff-list-wrap {
        transition: 0.5s all ease;
      }

      .create-filter {
        display: flex;

        .search-wrap {
          margin-left: 20px;
          flex: 1;
          width: 100%;
        }

        button {
          margin-bottom: 15px;
        }
      }

      .staff-list {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        padding-bottom: 20px;
        box-sizing: border-box;
        margin-bottom: 15px;

        &.table {
          & > div {
            overflow: unset;
          }
        }

        &-table-wrap {
          position: relative;
        }

        div.rdt_TableRow {
          padding: 13px 0;
        }

        div:first-child {
          &::-webkit-scrollbar {
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: ${Color.white};
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: ${Color.blueText};
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background: ${Color.blueLight};
            }

            &::-webkit-scrollbar-thumb:hover {
              background: ${Color.blueText};
            }
          }
        }
      }

      @media (max-width: 500px) {
        padding: 15px 20px;
      }
    }

    .edit_location {
      margin-bottom: 25px;

      .close-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0px 15px;

        .close {
          background: none;
        }
      }

      .edit_location_form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & > *:nth-child(1) {
          flex-basis: 45%;
        }

        & > *:nth-child(2) {
          flex-basis: 45%;
        }

        & > *:nth-child(3) {
          flex-basis: 100%;
        }

        .btn_wrapper {
          width: 100%;
        }
      }

      .location-group-logo {
        flex-basis: 100%;
        margin: 15px 0px;
      }

      .welcome_gift_wrapper {
        background-color: ${Color.white};
        padding: 16px;
        flex-basis: 100%;
        margin-bottom: 20px;
      }

      .welcome_gift_form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > *:nth-child(1) {
          flex-basis: 45%;
        }

        & > *:nth-child(2) {
          flex-basis: 45%;
        }

        & > *:nth-child(3) {
          flex-basis: 100%;
        }
      }

      .title_block {
        color: ${Color.blueDarkText};
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px 0px 16px;
      }
    }

    .sidebar-edit-location {
      .location-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      .location-group-google-autocomplete {
        .place-point-icon {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .location-group-logo {
        .dropZone > div .img img {
          position: absolute;
        }
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }

    .sidebar-edit-staff {
      .staff-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }

    @media (max-width: 500px) {
      padding: 15px 0px;
    }
  }

  .card {
    width: fit-content;
    background-color: ${Color.white};
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 30px;
    margin: 0px auto 20px;
    box-sizing: border-box;
    position: relative;

    .title {
      color: ${Color.blueDarkText};
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 7px;
    }

    .description {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .remove-modal {
    text-align: center;
    padding: 60px 50px;

    .title {
      color: ${Color.blueDarkText};
      font-size: 21px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    button {
      margin-top: 20px;
      width: auto;
      padding: 10px 20px;
    }
  }

  .reward_block {
    margin: 30px 0px 35px;
  }

  .reward_label_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 450px) {
      flex-wrap: wrap;
    }
  }

  .reward_label {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-right: 10px;
  }

  .reward_inputs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .isSelect {
      //padding-bottom: 12px;

      input {
        margin: 0 2px 2px;
      }

      ul.bottom, ul.top {
        margin: 0 2px 2px;
        width: calc(100% - 4px);
      }
        ul.bottom {
            overflow-y: visible;
        }
        

      @media (max-width: 1300px) {
      ul.top {
          flex-direction: column;
        }
      }
    }

    & > * {
        margin: 15px 0px;
        width: 47%;
      }
      & > *:last-child {
        flex-basis: 100%;
        max-width: 100%;
      }
      @media (max-width: 550px) {
        & > * {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
    .reward_expired {
      padding: 0px 5px;
      label {
        margin-bottom: 15px;
      }
    }
  }
 .promotionReward_close {
   max-height: 0px;
   overflow: hidden;
   transition: max-height 1s ease-in;
  }
  .promotionReward_open {
    max-height: 3150px;
    transition: max-height 1s ease-in;
  }
  .promotionReward_control {
    display: flex;
    align-items: center;
    background-color: ${Color.white};
    font-size: 18px;
    margin-bottom: 6px;
    & > span {
      width: 24px;
      height: 24px;
      margin-left: 4px;
      transition: transform 0.5s ease;
    }
    .promotionReward_control_rotate {
      transform: rotate(180deg);
    }
  }
  
  /* @media (min-width: 1300px) and (max-width: 1550px) {
    .main-content {
      .business-info-row,
      .locations-card-row {
        .business-info-left,
        .locations {
          width: calc(70% - 30px);
        }

        .business-info-right,
        .card {
          width: 30%;
        }
      }
    }
  } */
  @media (max-width: 1300px) {
    .main-content {
      .business-info-row,
      .locations-card-row {
        flex-direction: column-reverse;
        align-items: center;

        .business-info-left,
        .locations {
          width: 100%;
          margin-right: 0;
        }

        .business-info-right,
        .card {
          width: 100%;
          max-width: 380px;
          margin: 0px auto 30px;
        }
      }
    }
    .top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;

      .logo {
        line-height: 0;
        margin-right: 15px;

        img {
          object-fit: cover;
          height: 60px;
          width: 60px;
          border-radius: 100%;
        }
      }

      .right {
        width: calc(100% - 75px);
      }

      .name-notificationCount {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;

        .name {
          font-family: "Averta Std ExtraBold";
          font-size: 16px;
          line-height: 19px;
          color: #2f3640;
        }

        .notification-count {
          display: flex;
          align-items: center;

          .notification {
            margin-right: 15px;
          }

          .count {
            border: 1px solid ${Color.blue};
            border-radius: 4px;
            background-color: ${Color.white};
            padding: 0 5px;
            box-sizing: border-box;
            color: ${Color.blue};
            font-size: 12px;
            font-weight: 300;
            line-height: 21px;
          }

          @media (max-width: 400px) {
            flex-wrap: wrap;
            .notification {
              flex-basis: 100%;
              margin-right: 0px;
            }

            .count {
              flex-basis: 100%;
            }
          }
        }
      }

      .info {
        font-family: "Averta Std Semibold";

        .title {
          color: #bcbcbc;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          margin-right: 3px;
        }

        .content {
          color: ${Color.grey};
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }

    .promotion-count {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      .promotion-item {
        width: 49px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }

        .promotion-item-icon {
          margin: auto;
          width: max-content;
        }

        .promotion-item-icon-number {
          font-family: "Averta Std ExtraBold";
          color: #ffffff;
          position: absolute;
          top: -1px;
          left: -3px;
          font-size: 9px;
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          text-align: center;
          line-height: 49px;
        }
      }

      .promotion_done {
        width: 49px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .points-progress-line {
        width: 100%;
        display: flex;

        .progress-line {
          width: calc(100% - 85px);
          margin-right: 10px;
          border-radius: 4px;
          color: ${Color.white};
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          padding: 8px 15px;
          /* &.full { */
          background: #5771e1; /* } */

          * &.empty {
            background-color: #dcdef7;
            color: ${Color.blueDarkText};
          }
        }

        .progress-done {
          position: relative;
          line-height: 0;
          width: 75px;

          .text {
            position: absolute;
            top: 7px;
            left: 5px;
            color: ${Color.white};
            font-size: 10px;
            font-weight: 300;
            line-height: 11px;
            width: 45px;
            word-wrap: break-word;
          }

          .dark-text {
            color: ${Color.blueDarkText};
          }
        }
      }
    }

    &.full {
      .promotion-count {
        .promotion-item {
          svg {
            g {
              opacity: 1;
            }

            path {
              fill: ${Color.blue};
            }
          }
        }
      }
    }

    @media (max-width: 400px) {
      padding: 20px 10px;
    }
  }
  @media (max-width: 500px) {
    padding: 25px 0px;
  }


@media (max-width: 680px) {
  .main-content {
    .business-info-left {
      padding: 0px;

      .category-promotionName,
      .promotionType-promotionCount {
        flex-direction: column;

        .form-group {
          width: 100%;
        }

        .promotionName-group,
        .promotionCount-group {
          margin-left: 0;
        }
      }
    }

    .business-info-right {
      padding: 20px;
    }

    .locations-card-row {
      .locations {
        padding: 20px;

        .location-list {
          .location-item {
            padding: 20px;

            .staff-list-wrap {
              .create-filter {
                flex-direction: column;

                .search-wrap {
                  margin-left: 0;
                }

                button {
                  margin-bottom: 25px;
                }
              }

              .create-new-user {
                .input-wrap {
                  flex-direction: column;

                  div {
                    width: 100%;
                  }

                  .first_name-group,
                  .last_name-group {
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
      }

      .card {
        padding: 20px 15px;
      }
    }
  }
}

@media (max-width: 500px) {
  .main-content {
    .business-info-left {
      .button-row {
        margin-top: 30px;
        flex-direction: column;

        div,
        button {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }

        div {
          margin-bottom: 20px;
          padding: 10px 0;
        }
      }
    }
  }
}
`;

export const DaysWrapperDiv = styled.div`
  position: relative;
`;

export const SpanInputText = styled.span`
  position: absolute;
  top: 13px;
  left: 55px;
  font-size: 18px;
`

export { Wrapper };
