import React from "react";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { Wrapper } from "./styled";

interface Props {
  infoText: string;
}

const Info: React.FC<Props> = ({ infoText }) => {
  return (
    <Wrapper>
      <span className="info-tooltip">
        <InfoIcon />
        <span className="tooltip">{infoText}</span>
      </span>
    </Wrapper>
  );
};

export default Info;
