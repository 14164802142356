/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import Select from "../Select";
import CheckBox from "../CheckBox";
import workHours from "../../views/Registration/Step/RegistrationWelcomePromotion/const";
import { Wrapper } from "./styled";
import { DEFAULT_WORK_TIME_OBJ } from "./conts";

const WorkTime: React.FC<{
  workingTimeProp: any;
  formErrorMessage: any;
  setWorkingTimeProp: any;
}> = ({ workingTimeProp, formErrorMessage, setWorkingTimeProp }) => {
  const defaultWorkTime = Array.isArray(workingTimeProp)
    ? DEFAULT_WORK_TIME_OBJ
    : workingTimeProp;

  const [workingTime] = useState<{
    [index: string]: {
      allDay: boolean;
      isOpen: boolean;
      from: string | number;
      to: string | number;
      label: string;
    };
  }>(defaultWorkTime);

  // useEffect(() => {
  //   setWorkingTime(defaultWorkTime);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Wrapper>
      <div className="form-group">Opening Hours</div>
      <div className="line" />
      <div className="top_table_wrapper">
        <CheckBox
          onChange={(isChecked) => {
            if (!isChecked) return;
            const workTime = { ...workingTime };
            Object.keys(workingTime).forEach((weekday) => {
              workTime[weekday].isOpen = isChecked;
            });
            setWorkingTimeProp(workTime);
          }}
          isChecked={Object.values(workingTime).every(({ isOpen }) => isOpen)}
        />
        <p className="table_title">Day</p>
        <p className="table_title">Start</p>
        <p className="table_title">Finish</p>
      </div>
      {Object.keys(workingTime).map((weekDay) => (
        <div key={weekDay} className="work-time-row">
          <CheckBox
            isChecked={workingTime[weekDay].isOpen}
            onChange={(isChecked) => {
              const time = { ...workingTime };
              time[weekDay].isOpen = isChecked;
              setWorkingTimeProp(time);
            }}
          />
          <div className="form-group small weekday-label">
            {workingTime[weekDay].label ?? weekDay}
          </div>
          <div className="form-group small">
            <label
              htmlFor={`work-time-${weekDay}`}
              className="checkbox blue-checkbox all-day-checkbox"
            >
              <span className="checkbox-input-wrap">
                <input
                  onChange={({ target }) => {
                    const time = { ...workingTime };
                    time[weekDay].allDay = target.checked;
                    if (target.checked) {
                      time[weekDay].to = "";
                      time[weekDay].from = "";
                    }
                    setWorkingTimeProp(time);
                  }}
                  disabled={!workingTime[weekDay].isOpen}
                  checked={workingTime[weekDay].allDay}
                  type="checkbox"
                  id={`work-time-${weekDay}`}
                />
                <span className="checkbox-view" />
              </span>
              <div className="text">All Day</div>
            </label>
            {formErrorMessage[`work_time.${weekDay}.all_day`] ? (
              <div className="form-error-message">
                {formErrorMessage[`work_time.${weekDay}.all_day`]}
              </div>
            ) : null}
          </div>

          <div className="form-group large">
            <Select
              placeholder={
                !workingTime[weekDay].isOpen
                  ? ""
                  : !workingTime[weekDay].allDay
                  ? "Opening Time"
                  : "00:00"
              }
              selectorList={workHours}
              disabled={
                workingTime[weekDay].allDay || !workingTime[weekDay].isOpen
              }
              required={!workingTime[weekDay].allDay}
              positionForShow="top"
              defaultValue={
                workingTime[weekDay].isOpen
                  ? workHours.find((element) => {
                      return element.value === workingTime[weekDay].from;
                    })
                  : { label: "", value: "" }
              }
              getValue={(value) => {
                const time = { ...workingTime };
                time[weekDay].from = value;
                setWorkingTimeProp(time);
              }}
              hideSelectorFilter
              lock={workingTime[weekDay].allDay || !workingTime[weekDay].isOpen}
            />
            {formErrorMessage[`work_time.${weekDay}.from`] ? (
              <div className="form-error-message">
                {formErrorMessage[`work_time.${weekDay}.from`]}
              </div>
            ) : null}
          </div>
          <div className="form-group large">
            <Select
              placeholder={
                !workingTime[weekDay].isOpen
                  ? ""
                  : !workingTime[weekDay].allDay
                  ? "Opening Time"
                  : "00:00"
              }
              selectorList={workHours}
              positionForShow="top"
              disabled={
                workingTime[weekDay].allDay || !workingTime[weekDay].isOpen
              }
              required={!workingTime[weekDay].allDay}
              defaultValue={
                workingTime[weekDay].isOpen
                  ? workHours.find((element) => {
                      return element.value === workingTime[weekDay].to;
                    })
                  : { label: "", value: "" }
              }
              getValue={(value) => {
                const time = { ...workingTime };
                time[weekDay].to = value;
                setWorkingTimeProp(time);
              }}
              hideSelectorFilter
              lock={workingTime[weekDay].allDay || !workingTime[weekDay].isOpen}
            />
            {formErrorMessage[`work_time.${weekDay}.to`] ? (
              <div className="form-error-message">
                {formErrorMessage[`work_time.${weekDay}.to`]}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </Wrapper>
  );
};

export default WorkTime;
