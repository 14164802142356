import React, { FC, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import Calendar from "src/components/Calendar";
import InfoTooltip from "src/components/infoTooltip";
import { getSubscriptionData } from "src/api/userProfile";
import { getCustomerGroupsAxios } from "src/api/customers";
import {
  createAdminCampaignsAxios, getSingleAdminCampaignsAxios,
} from "src/api/campaigns";

import checkEmailImg from "src/assets/img/check-your-email.svg";
import { Wrapper } from "./styled";

interface RouteParams {
  id?: string;
}

const PUSH_MESSAGE_LIMIT = 130;

const QuickNotification: FC = () => {
  const params = useParams<RouteParams>();
  const requestParamtrs = {
    limit: "10",
    page: "1",
    search: null,
  };
  const history = useHistory();
  const [campaignName, setCampaignName] = React.useState<string>("");
  const [campaignSentTime, setCampaignSentTime] = React.useState<boolean>(true);
  const [limitExceeded, setLimitExceeded] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [inAppNotification, setInAppNotification] =
    React.useState<boolean>(true);
  const [pushNotification, setPushNotification] = React.useState<boolean>(true);
  const [typePromotion, setTypePromotion] = React.useState<boolean>(true);
  const [campaignMessage, setCampaignMessage] = React.useState<string>("");
  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = React.useState<string>("");

  const getDataForEditCampaign = () => {
    if (params?.id) {
      setFormLoading(true);
      getSingleAdminCampaignsAxios({ id: params.id }).then(({data}) => {
        const timeToSend = new Date(data.campaign.time_to_send.date);

        setCampaignName(data.campaign.name);
        if (data.campaign.sendNow === 1) {
          setCampaignSentTime(true);
        } else {
          setCampaignSentTime(false);
          setStartDate(
            new Date(
              timeToSend.getTime() - timeToSend.getTimezoneOffset() * 60000
            )
          );
        }
        setTypePromotion(false);
        setInAppNotification(data.campaign.is_in_app_notification);
        setPushNotification(data.campaign.is_push_notification);
        setCampaignMessage(data.campaign.message);
        setFormLoading(false);
      });
    }
  };

  useEffect(() => {
    getDataForEditCampaign();
    setFormLoading(true);
    getSubscriptionData().then((response) => {
      if (
        response.data.data.total_campaign_count >=
          response.data.data.campaigns_max_count &&
        !params?.id
      ) {
        setLimitExceeded(true);
      }
      setFormLoading(false);
    });
  }, []); //eslint-disable-line

  const createRequestParamtrs = {
    name: campaignName,
    send_now: campaignSentTime,
    send_date: new Date(startDate).getTime(),
    message: campaignMessage,
    in_app_notification: inAppNotification,
    push_notification: pushNotification,
  };

  const updateRequestParamtrs = {
    id: params.id,
    name: campaignName,
    send_now: campaignSentTime,
    send_date: new Date(startDate).getTime(),
    message: campaignMessage,
    in_app_notification: inAppNotification,
    push_notification: pushNotification,
  };

  const handleCreateCampaign = (event: React.FormEvent) => {
    event.preventDefault();
    setFormLoading(true);
    if (params?.id) {
      createAdminCampaignsAxios(updateRequestParamtrs)
        .then(() => {
          history.push("/notification/manage-notification");
        })
        .catch((error) => {
          setFormErrorMessage(error.data.message);
          setFormLoading(false);
        });
    } else {
      createAdminCampaignsAxios(createRequestParamtrs)
        .then(() => {
          history.push("/notification/manage-notification");
        })
        .catch((error) => {
          setFormErrorMessage(error.data.message);
          setFormLoading(false);
        });
    }
  };

  const clearFields = (event: React.FormEvent) => {
    event.preventDefault();
    setCampaignName("");
    setCampaignMessage("");
    setPushNotification(false);
    setCampaignSentTime(true);
    setStartDate(new Date());
  };

  return (
    <Wrapper>
      <div className="page-title">Create Notification</div>
      <div className="nav-subpages">
        <div className="nav-subpage active-page">
          <button type="button">Create Notification</button>
        </div>
      </div>
      <div className="create-campaign-wrap">
        {limitExceeded && (
          <>
            <div className="alert">
              <p>
                You have reached notification limit in your plan. In order to be
                able to create more notification upgrade your plan.
                <Link className="upgrade-btn" to="/user-profile/update-plan/">
                  {" "}
                  Upgrade Plan
                </Link>
              </p>
            </div>
            <div className="image-container">
              <img src={checkEmailImg} alt="" />
            </div>
          </>
        )}
        {limitExceeded || (
          <>
            <div className="alert">
              <p>
                Send a quick message to your customers. You can reach them using
                the Lynked APP, TEXT/SMS or EMAIL. The message can be sent right
                away or scheduled for future dates. You will be able to preview
                the message before sending it.
              </p>
              <p>
                Please note, it may take several minutes for messages to reach
                all your recipients. For more advanced features, please use our
                Notification Manager.
              </p>
            </div>
            <form
              onSubmit={handleCreateCampaign}
              className={`${formLoading ? "loading-blue" : ""}`}
            >
              <div className="section-title">General Information</div>
              <div className="section-sub-title mb-20">
                Notification name
                <InfoTooltip infoText="Create a notification name to get the attention of your customers. E.g. “Free Pastry” This is the title your customers will see at the top of the notification." />
              </div>
              <div className="input-block mb-20">
                <Input
                  placeholder="Name"
                  defaultValue={campaignName}
                  getValue={(value) => setCampaignName(value)}
                  required
                />
              </div>
              <div className="section-sub-title mb-20">
                When would you like the notification sent?
              </div>
              <div className="type-radio-wrap mb-12">
                <div className="radio-item">
                  <label htmlFor="campaignSentTimeNow">
                    <input
                      type="radio"
                      id="campaignSentTimeNow"
                      name="campaignSentTime"
                      value="now"
                      required
                      checked={campaignSentTime === true}
                      onChange={() => setCampaignSentTime(true)}
                    />
                    <i />
                    <span className="radio-text">Now</span>
                  </label>
                </div>
                <div className="radio-item">
                  <label htmlFor="campaignSentTimeLater">
                    <input
                      type="radio"
                      id="campaignSentTimeLater"
                      name="campaignSentTime"
                      value="later"
                      checked={campaignSentTime === false}
                      onChange={() => setCampaignSentTime(false)}
                    />
                    <i />
                    <span className="radio-text">Later</span>
                  </label>
                </div>
              </div>
              {campaignSentTime === false ? (
                <div className="choose-campaing-data__block">
                  <div className="choose-data__block">
                    <div className="label">Start date</div>
                    <Calendar
                      selected={startDate}
                      onChange={(e)=>setStartDate(e >= new Date() ? e : new Date() )}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </div>
                </div>
              ) : null}
              <div className="section-sub-title mb-20">User Notification:</div>
              <div className="checkbox-container">
                <label
                  htmlFor="appNootification"
                  className="checkbox white-checkbox"
                >
                  <span className="checkbox-input-wrap">
                    <input
                      type="checkbox"
                      id="appNootification"
                      onChange={() => setInAppNotification(!inAppNotification)}
                      disabled
                      checked
                    />
                    <span className="checkbox-view" />
                  </span>
                  <div className="text">In app notification</div>
                </label>
                <label
                  htmlFor="pushNootification"
                  className="checkbox white-checkbox"
                >
                  <span className="checkbox-input-wrap">
                    <input
                      type="checkbox"
                      id="pushNootification"
                      checked={pushNotification}
                      onChange={() => setPushNotification(!pushNotification)}
                    />
                    <span className="checkbox-view" />
                  </span>
                  <div className="text">Push notification</div>
                </label>
              </div>
              <div className="separate-line"> </div>
              <div className="section-title">
                Push notification
                <InfoTooltip infoText="Enter the detail of your notification here, the title will be taken from the “Notification Name” field. Lynked will automatically differentiate between users when “ {firstName} {lastName}”, is entered in the text field, you do not need to include the specific names of individuals, Lynked will do that for you." />{" "}
              </div>
              <div className="alert mb-24">
                <p>
                  The message will be sent via push notification in the user
                  app.
                </p>
              </div>
              <div className="message-block">
                <Textarea
                  placeholder="This will be the message that goes out as a text and the email body."
                  required
                  defaultValue={campaignMessage}
                  getValue={(value) => {
                    setCampaignMessage(value);
                  }}
                />
              </div>
              <div className="hint-message">
                {campaignMessage.length > PUSH_MESSAGE_LIMIT ? (
                  <p className="limit_error">
                    {`You have reached the ${PUSH_MESSAGE_LIMIT} character limit.`}
                  </p>
                ) : null}
                <p>Character Limit (130 characters)</p>
                <p>
                  {`Length: ${campaignMessage.length} of ${PUSH_MESSAGE_LIMIT} (space = 1 character, line break = 2
                  characters)`}
                </p>
                <p>An example of Quick Notification text:</p>
                <p>{"“Hi {firstName} {lastName},"}</p>
                <p>
                  Heres 10% off your next order! Just show your QR code in store
                  to redeem!”
                </p>
              </div>
              {formErrorMessage && (
                <div className="form-error-message">{formErrorMessage}</div>
              )}
              <div className="separate-line"> </div>
              <div className="button-container">
                <button
                  type="button"
                  onClick={(event) => clearFields(event)}
                  className="button-white"
                >
                  Reset
                </button>
                <div>
                  <button
                    type="button"
                    onClick={() => history.push("/notification/manage-notification")}
                    className="button-white cancel-btn"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={campaignMessage.length > PUSH_MESSAGE_LIMIT}
                    type="submit"
                    className="btn-blue"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default QuickNotification;
