import React from "react";
import { Link } from "react-router-dom";

import * as Types from "./types";
import { Wrapper } from "./styled";

const MunuItem: React.FC<Types.Props> = ({ item }) => {
  return (
    <Wrapper>
      {item.type === "item" ||
      item.type === "item-parent" ||
      item.type === "item-children" ? (
        <Link to={item.path}>
          {item.icon ? <span className="icon">
            <img src={item.icon} alt={item.name}/>
          </span> : null}
          
          <span className="name">{item.name}</span>
        </Link>
      ) : item.type === "external" ? (
        <a href={item.path} target="_blank" rel="noreferrer">
          <span className="icon">
            <img src={item.icon} alt={item.name} />
          </span>
          <span className="name">{item.name}</span>
        </a>
      ) : null}
    </Wrapper>
  );
};

export default MunuItem;
