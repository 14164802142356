import { Color } from "src/types/color";
import styled, {keyframes} from "styled-components";


export const Wrapper = styled.div`
    .top-bar div.search-wrap {
        margin-left: 0;
    }
    .staff-top-bar{
        justify-content: start;
        gap: 20px;
    }
    .table-wrap{
        overflow: unset;
        .table {
            & > div {
                overflow: unset;
            }



            div.rdt_TableRow {

                & input {
                    width:75%
                }
            }
            div:first-child {
                &::-webkit-scrollbar {
                    height: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background: ${Color.white};
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: ${Color.blueText};
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background: ${Color.blueLight};
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: ${Color.blueText};
                    }
                }
            }
            .rdt_Table{
                .rdt_TableBody{
                    .rdt_TableRow{
                        .rdt_TableCell{
                            align-items: center;
                            &>div{
                                //width: 80%;
                                .isSelect{
                                    ul.bottom{
                                        max-height: 80px;
                                    }
                                }
                                .calendar-wrapper{
                                    input {
                                        width: 77%;
                                    }
                                }
                            }
                        }
                        .action {
                            //display: flex;
                            //align-items: center;
                            //    margin-bottom: 24px;
                            //    margin-top: 10px;
                            //    width: 20%;
                            //    .edit {
                            //        display: block;
                            //        line-height: 0;
                            //        height: 24px;
                            //        width: 24px;
                            //        opacity: 0.6;
                            //        margin-right: 25px;
                            //        background: none;
                            //        &:hover {
                            //            opacity: 1;
                            //        }
                            //    }
                            //    .delete {
                            //        display: block;
                            //        line-height: 0;
                            //        height: 24px;
                            //        width: 24px;
                            //        opacity: 0.6;
                            //        background: none;
                            //        padding: 0;
                            //        &:hover {
                            //            opacity: 1;
                            //        }
                            //    }
                            //    .info {
                            //        display: block;
                            //        line-height: 0;
                            //        height: 24px;
                            //        width: 24px;
                            //        margin-right: 25px;
                            //        background: none;
                            //        cursor: pointer;
                            //        position: relative;
                            //        & > span {
                            //            z-index: -1;
                            //            opacity: 0;
                            //            background-color: $blueDarkText;
                            //            position: absolute;
                            //            font-size: 14px;
                            //            width: 96px;
                            //            color: $white;
                            //            padding-left: 10px;
                            //            padding-right: 10px;
                            //            text-align: center;
                            //            border-radius: 4px;
                            //            padding-top: 5px;
                            //            padding-bottom: 5px;
                            //            transition: all 0.3s ease;
                            //            line-height: 20px;
                            //            top: -55px;
                            //            left: -35px;
                            //            box-sizing: border-box;
                            //        }
                            //        svg {
                            //            opacity: 0.6;
                            //            &:hover {
                            //                opacity: 1;
                            //
                            //                & + span {
                            //                    z-index: 2;
                            //                    opacity: 1;
                            //                }
                            //            }
                            //            path:not([fill="none"]) {
                            //                fill: rgb(0, 13, 70);
                            //            }
                            //        }
                            //    }
                            //}
                        }
                    }
                }
            }
        }
    }
    
`;
