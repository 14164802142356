import React, {useState} from 'react';
import {Wrapper} from "./style";
import Input from "../components/Input";
import {REACT_APP_EPOS_NOW_API, REACT_APP_EPOS_NOW_STG} from "src/types/env";
import Bugsnag from "@bugsnag/js";

const EposNow = () => {
  const [discountID, setDiscountId] = useState<number>(0)
  const [token, setToken] = useState<string>('')
  const [barcode, setBarcode] = useState<string>('')
  window.onload = async function () {
    // @ts-ignore
    const locationId = EposnowAPI.getLocationID();
    await fetch(`${REACT_APP_EPOS_NOW_API}/eposnow/info/get`, {
      method: 'POST',
      body: JSON.stringify({
        locationId: locationId
      })
    }).then(res => res.json()).then(res => {
      Bugsnag.notify(new Error(JSON.stringify(res)))
      setDiscountId(res.discount_id)
      setToken(res.token)
    }).catch(err => {
      console.log(JSON.stringify(err))
      Bugsnag.notify(new Error(JSON.stringify(err)))
    })
  }
  
  // @ts-ignore
  const eposSDK = window?.EposNowTillSDK;
  const version = 1;
  // @ts-ignore
  EposnowAPI.setVersion(version);
  // Register the event listener to publish transaction data between the app and the till
  eposSDK.TransactionPublisher.register(window);
  
  const create = async () => {
    const transaction_data = new eposSDK.Transaction();
    // @ts-ignore
    const locationId = EposnowAPI.getLocationID();
    // @ts-ignore
    const staffId = EposnowAPI.getStaffID();
    // @ts-ignore
    const deviceId = EposnowAPI.getDeviceID();
    // @ts-ignore
    const currentTransactionProducsIds = EposnowAPI.getTransactionProducts();
    let productsArr: any[] = []
    let categoriesIDs: any[] = []
    await fetch(`${REACT_APP_EPOS_NOW_STG}/api/v4/Product/List?ids=${currentTransactionProducsIds.join('&ids=')}`, {
      headers: {
        'Authorization': `Basic ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(res =>
      res.json()
    ).then(response => {
      response.forEach((product: any) => {
        !categoriesIDs.includes(product.CategoryId) && categoriesIDs.push(product.CategoryId)
      })
      productsArr = response
    }).catch(err=>{
      Bugsnag.notify(new Error('Products'+JSON.stringify(err)))
    })
    
    let categoryArr: any[] = []
    await fetch(`${REACT_APP_EPOS_NOW_STG}/api/v4/Category/List?ids=${categoriesIDs.join('&ids=')}`, {
      headers: {
        'Authorization': `Basic ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(res =>
      res.json()
    ).then(response => {
      categoryArr = response
    }).catch(err=>{
      Bugsnag.notify(new Error('Category'+JSON.stringify(err)))
    })
    
    const response = await fetch(`${REACT_APP_EPOS_NOW_API}/eposnow/member`, {
      method: 'POST',
      body: JSON.stringify({
        barcode: barcode,
        products: productsArr,
        category: categoryArr,
        deviceId: deviceId,
        locationId: locationId,
        staffId: staffId,
      })
    })
    
    const res = await response.json()
    let voucherSum = 0;
    
    res.vouchers.forEach((voucher: any) => {
      voucherSum += voucher.rewardNumber
    })
    
    if (voucherSum > 0) {
      transaction_data.Discount = {
        reasonId: discountID,
        amount: voucherSum,
      }
    }
    
    transaction_data.TransactionDetails.push(new eposSDK.TransactionDetail("Lynked-Loyalty", barcode));
    //
    /* Publish the transaction data to the till  */
    eposSDK.TransactionPublisher.publish(transaction_data);
    eposSDK.submitForm(window);
  }
  return (
    <Wrapper>
      <div className="logo_wrap">
        <img src="/static/media/LogoSidebar.7a40b670.png" alt="LogoSidebar"/>
      </div>
      <div className="form">
        <div className="inputWrapp">
          <p className="label">Input your barcode</p>
          <Input
            placeholder="Input Your Barcode"
            defaultValue={barcode}
            required
            maxLength={28}
            getValue={(value) => setBarcode(value)}
          />
        </div>
        <button className="submitBtn" onClick={create}>Submit Barcode</button>
      </div>
    
    </Wrapper>
  );
};

export default EposNow;
