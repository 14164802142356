import DashboardIcon from "src/assets/img/menu-item/dashboard.svg";
import CampaignsIcon from "src/assets/img/menu-item/campaigns.svg";
import CampaignsQuickIcon from "src/assets/img/menu-item/campaigns-quick.svg";
import CampaignsManageIcon from "src/assets/img/menu-item/campaigns-manage.svg";
import UserProfileIcon from "src/assets/img/menu-item/user-profile.svg";

import PromotionsIcon from "src/assets/img/menu-item/promotions.svg";
import PromotionsManageIcon from "src/assets/img/menu-item/promotion-manage.svg";
import PosRulesIcon from "src/assets/img/menu-item/pos-rules.svg";
import Settingscon from "src/assets/img/menu-item/settings.svg";
// import PromotionsCreateIcon from "src/assets/img/menu-item/promotion-create.svg";

import CustomersIcon from "src/assets/img/menu-item/customers.svg";
// import CustomerListIcon from "src/assets/img/menu-item/customer-list.svg";
import CustomerGroupsIcon from "src/assets/img/menu-item/customer-groups.svg";
import VouchersIcon from "src/assets/img/menu-item/vouchers.svg";
// import RewardsIcon from "src/assets/img/menu-item/rewards.svg";
// import InstantDealsIcon from "src/assets/img/menu-item/instant-deals.svg";
// import OnlineShopIcon from "src/assets/img/menu-item/online-shop.svg";
// import ReportsIcon from "src/assets/img/menu-item/reports.svg";
import HelpIcon from "src/assets/img/menu-item/help.svg";
// import FeedbackIcon from "src/assets/img/menu-item/feedback.svg";

import {MenuList} from "./types";

export const allMenuList: Array<MenuList> = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    type: "item",
    path: "/",
    active: ['all', 'shopify', 'lightSpeed', 'pos', 'admin']
  },
  {
    icon: UserProfileIcon,
    name: "Account Management",
    type: "item",
    path: "/account-management",
    activeSubPath: [
      "/account-management/edit-personal-details",
      "/account-management/update-plan",
    ],
    active: ['all','shopify','lightSpeed', 'pos'],
  },
  {
    icon: PromotionsManageIcon,
    name: "Outlets and Users",
    type: "item",
    path: "/outlets-and-users",
    active: ['all','shopify','lightSpeed', 'pos'],
  },
  {
    icon: PromotionsIcon,
    name: "Your Loyalty Program",
    type: "item",
    path: "/your-loyalty-program",
    active: ['all','shopify','lightSpeed', 'pos'],
  },
  {
    icon: PromotionsIcon,
    name: "Minimum Spend",
    type: "item",
    path: "/minimum-spend",
    active: ['minSpend'],
  },
  {
    icon: CampaignsIcon,
    name: "Campaigns",
    type: "item-parent",
    path: "/campaigns/manage-campaigns",
    children: [
      {
        icon: CampaignsManageIcon,
        name: "Manage Campaigns",
        type: "item-children",
        path: "/campaigns/manage-campaigns",
      },
      {
        icon: CampaignsQuickIcon,
        name: "Create Campaign",
        type: "item-children",
        path: "/campaigns/create-campaign",
      },
      {
        icon: CustomerGroupsIcon,
        name: "Customer Groups",
        type: "item-children",
        path: "/customers/customer-groups",
        activeSubPath: ["/customers/create-customer-group"],
      },
    ],
    active: ['all','shopify', 'lightSpeed', 'pos', 'campaigns'],
  },{
    icon: CampaignsIcon,
    name: "Notification",
    type: "item-parent",
    path: "/notification/manage-notification",
    children: [
      {
        icon: CampaignsManageIcon,
        name: "Manage Notification",
        type: "item-children",
        path: "/notification/manage-notification",
      },
      {
        icon: CampaignsQuickIcon,
        name: "Create Notification",
        type: "item-children",
        path: "/notification/create-notification",
      },
      // {
      //   icon: CustomerGroupsIcon,
      //   name: "Customer Groups",
      //   type: "item-children",
      //   path: "/customers/customer-groups",
      //   activeSubPath: ["/customers/create-customer-group"],
      // },
    ],
    active: ['admin'],
  },
  {
    icon: CustomersIcon,
    name: "Your Customers",
    type: "item",
    path: "/customers/customer-list",
    active: ['all','shopify', 'lightSpeed', 'pos'],
    
  },
  {
    icon: PosRulesIcon,
    name: "POS Rules",
    type: "item",
    path: "/pos-rules",
    active: ['shopify', 'lightSpeed', 'pos'],
  },
  {
    icon: PosRulesIcon,
    name: "Pixel POS Rules",
    type: "item",
    path: "/site-pos-rules",
    active: ['sitePos'],
  },
  {
    icon: PosRulesIcon,
    name: "External Pos Integration",
    type: "item",
    path: "/external-pos-integration",
    active: ['lightSpeed'],
  },
  {
    icon: CustomersIcon,
    name: "E-Commerce Rule",
    type: "item",
    path: "/e-commerce",
    active: ['shopify'],
  },
  {
    icon: CustomersIcon,
    name: "Staff members",
    type: "item",
    path: "/staff-members",
    active: ['staff'],
    
  },
  {
    icon: DashboardIcon,
    name: "Staff Discount Dashboard",
    type: "item",
    path: "/staff-discount-dashboard",
    active: ['staff-discount']
  },
  {
    icon: CustomersIcon,
    name: "Staff discounts",
    type: "item",
    path: "/staff-discount",
    active: ['staff-discount'],
  },
  {
    icon: CustomersIcon,
    name: "Staff members",
    type: "item",
    path: "/staff-discount-members",
    active: ['staff-discount'],
    
  },
  {
    icon: VouchersIcon,
    name: "Spend Card",
    type: "item",
    path: "/spend-card",
    active: ['spend-card'],
  },
  {
    icon: CustomersIcon,
    name: "Product discount",
    type: "item",
    path: "/product-discount",
    active: ['productDiscount'],
  },
  {
    icon: PromotionsManageIcon,
    name: "Businesses",
    type: "item",
    path: "/admin-businesses",
    active: [ 'admin'],
    // activeSubPath: [
    //   "/account-management/edit-personal-details",
    //   "/account-management/update-plan",
    // ],
  },
  {
    icon: CustomersIcon,
    name: "Users",
    type: "item",
    path: "/admin-users",
    active: ['admin'],
  },
  {
    icon: Settingscon,
    name: "Businesses Settings",
    type: "item",
    path: "/businesses-settings",
    active: ['admin'],
  },
  {
    icon: CustomersIcon,
    name: "Adverts",
    type: "item-parent",
    path: "/admin-adverts",
    active: ['admin'],
    children: [
      {
        icon: '',
        name: "Featured Partners",
        type: "item-children",
        path: "/admin-adverts",
      },
      {
        icon: '',
        name: "Offers (Outlets)",
        type: "item-children",
        path: "/admin-adverts/offers-outlets",
      },
      {
        icon: '',
        name: "Offers (Brands)",
        type: "item-children",
        path: "/admin-adverts/offers-brands",
      },
    ],
  }
]

export const menuBottomList: MenuList[] = [
  {
    icon: HelpIcon,
    name: "Help",
    type: "external",
    path: "https://lynkedloyalty.com/contact-us/",
  },
  // {
  //   icon: FeedbackIcon,
  //   name: "Feedback",
  //   type: "item",
  //   path: "/feedback",
  // },
];
