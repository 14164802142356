import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import LogoSidebar from "src/assets/img/LogoSidebar.png";

import Menu from "./Menu";

import {Wrapper} from "./styled";
import {
  menuBottomList, allMenuList
} from "./menuList";
import {useTypedSelector} from "src/hooks/useTypedSelector";
import {MenuList} from "./types";

const Sidebar: React.FC = () => {
  const history = useHistory();
  const [visibleMenu, setVisibleMenu] = React.useState<boolean>(true);
  const {loggedUser} = useTypedSelector((state) => state.auth);
  const [showMenuList, setShowMenuList] = useState<MenuList[]>([])
  const role = JSON.parse(localStorage.getItem("user")!)?.role
  
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    loggedUser.user.allow_staff ? history.push('/staff-members') : null;
  }, [loggedUser.user.allow_staff])
  useEffect(() => {
    
    let activeMenuTab = []
    loggedUser.user.allow_shopify && activeMenuTab.push('shopify')
    loggedUser.user.allow_lightspeed && loggedUser.user.allow_pos && (
      activeMenuTab.push('lightSpeed')
    )
    loggedUser.user.allow_pos && (
      activeMenuTab.push('pos')
    )
    loggedUser.user.allow_cbePP && (
      activeMenuTab.push('sitePos')
    )
    loggedUser.user.allow_min_spend && (
      activeMenuTab.push('minSpend')
    )
    loggedUser.user.allow_campaigns && (
      activeMenuTab.push('campaigns')
    )
    loggedUser.user.allow_product_discount && (
      activeMenuTab.push('productDiscount')
    )
    loggedUser.user.role === 3 && (
      activeMenuTab.push('admin')
    )
    loggedUser.user.allow_staff && (
      activeMenuTab.push('staff')
    )
    loggedUser.user.allow_staff_discount && (
      activeMenuTab.push('staff-discount')
    )
    loggedUser.user.allow_spend_card && (
      activeMenuTab.push('spend-card')
    )
    // eslint-disable-next-line no-mixed-operators
    if ((activeMenuTab.length === 0 || !activeMenuTab.includes('staff')) && loggedUser.user.role !== 3) {
    
    activeMenuTab.unshift('all')
  }
    let resultMenuArr: any[] = []
    activeMenuTab.map(tab => {
      resultMenuArr.push(...allMenuList.filter(item => item.active?.includes(tab)))
    })
    // @ts-ignore
    setShowMenuList([...new Set(resultMenuArr)])
    // allMenuList.filter(item => item.active?.includes('all'))
  }, []);
  const showMenu = () => {
    setVisibleMenu(!visibleMenu);
  };
  useEffect(() => {
    return history.listen(() => {
      setVisibleMenu(false);
    });
  }, [history]);
  
  return (
    <Wrapper>
      <div className="logo-wrap">
        <div className="logo-container">
          <img src={LogoSidebar} alt="LogoSidebar"/>
        </div>
        <button
          type="button"
          id="burger-icon"
          onClick={showMenu}
          className={visibleMenu ? "open" : ""}
        >
          <span> </span>
          <span> </span>
          <span> </span>
        </button>
      </div>
      <div className={visibleMenu ? "show-mobile-menu content" : "content"}>
        <div className="main-btn">
          {loggedUser.user.allow_staff || role === 3 ? null : <Link className="btn-blue" to="/campaigns/create-campaign">
            Create Campaign
          </Link>}
        
        </div>
        <div className="nav-items">
          {/*{logedUser.user.allow_staff ? (*/}
            <Menu menuList={showMenuList}/>
          {/*) : logedUser.user.allow_shopify ? (*/}
          {/*  <Menu menuList={menuListShopify}/>*/}
          {/*) : logedUser.user.allow_lightspeed && logedUser.user.allow_pos ? (*/}
          {/*  <Menu menuList={menuListLightSpeed}/>*/}
          {/*) : logedUser.user.allow_pos ? (*/}
          {/*  <Menu menuList={menuListPos}/>*/}
          {/*) : logedUser.user.role === 3 ? (*/}
          {/*  <Menu menuList={menuListAdmin}/>*/}
          {/*) : (*/}
          {/*  <Menu menuList={menuList}/>*/}
          {/*)}*/}
        </div>
        <div className="nav-items nav-bottom-items">
          <Menu menuList={menuBottomList}/>
        </div>
      </div>
      <button
        type="button"
        onClick={showMenu}
        className={visibleMenu ? "enable-blur-content" : "disable-blur-content"}
      >
        {}
      </button>
    </Wrapper>
  );
};

export default Sidebar;
