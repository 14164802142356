import React, { useEffect } from "react";

import { PropsInterface } from "./types";
import { Wrapper } from "./styled";

const Textarea: React.FC<PropsInterface> = ({
  label,
  placeholder,
  defaultValue,
  required,
  disabled,
  getValue,
}) => {
  const [value, setValue] = React.useState<string>(defaultValue || "");

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]); //eslint-disable-line

  const handleChange = (currentValue: string) => {
    setValue(currentValue);
    if (getValue) getValue(currentValue);
  };

  return (
    <Wrapper required={required} isLabel={!!label}>
      <span className="label">{label}</span>
      <textarea
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(event) => {
          return handleChange(event.target.value);
        }}
      />
    </Wrapper>
  );
};

export default Textarea;
