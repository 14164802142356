import styled from 'styled-components';
import { Color } from "../../../../types/color";

export const WrapperTransaction = styled.div`
  
  .transactionWrapper{
    //position: absolute;
    z-index: 100;
    //top: 0;
    //left: 0;
    width: 100%;
    padding: 20px 0;
  }
  
  //.transaction-header{
  //  background-color: #F7F8FC;
  //  padding: 25px 30px;
  //}
  //
  //.topBar{
  //  width: 20%;
  //  display: flex;
  //  align-items: center;
  //  cursor: pointer;
  //
  //  & svg{
  //    width: 25px;
  //    height: 25px;
  //    margin-right: 15px;
  //  }
  //}
  .transaction-body{
    background-color: #fff;
    padding: 0 30px;
  }
  .transaction-title{
    font-size: 32px;
    color: #000d46;
    font-weight: 450;
    margin: 0 0 15px;
  }
  .transaction-description{
    font-size: 14px;
    font-weight: 450;
    color: #000d46;
    opacity: 0.6;
    margin-bottom: 30px;
  }

  .purchase-wrapper{
    padding: 20px 0 20px;
  }
  .main-info{
    display: flex;
    justify-content: space-around;
    align-items: inherit;
    margin-bottom: 30px;
  }
  .main-info-item{
    max-width: 300px;
  }
  .item-label{
    font-size: 14px;
    font-weight: 450;
    color: #000d46;
    margin-bottom: 15px;
  }
  .item-value{
    font-size: 16px;
    font-weight: 450;
    color: #000d46;
  }
    .total_wrap{
        display: flex;
        align-items: center;
    }
  .total-prise{
    margin-top: 20px;
    margin-right: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    & p{
      font-size: 16px;
      font-weight: 500;
      margin-left: 40px;
    }
  }
  .chart {
    background: ${Color.white};
    padding: 0 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 8px 8px 8px;

  }
  
`;


const Wrapper = styled.div`
  margin-top: 30px;

  .chart {
    background: ${Color.white};
    padding: 30px 30px 0;
    border-radius: 4px;
    box-shadow: 0 -4px 5px 0px rgb(0 0 0 / 10%);
  
  }
  .doughnut_items_wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    & > * {
      margin: 0px 15px;
    }
    &::-webkit-scrollbar {
      height: 12px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: ${Color.blueBackground};
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${Color.blue};
      border-radius: 3px;
    }
    .not_found {
      font-size: 20px;
      min-height: 200px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .doughnut_item_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    min-height: 200px;
    margin-bottom: 25px;
    .doughnut_location {
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      margin: 12px 0px;
    }
    .center_wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100px;
      height: 100px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      text-align: center;
      .center_wrapper_title {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: ${Color.grey};
      }
      .center_count {
        font-size: 28px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
  .doughnut_legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    .legend_item {
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    .legend_wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
      .legend_color {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 2px;
        background-color: ${Color.blueLightMedium};
      }
    }
  }
  .doughnut_legend_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .legend_item_color {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      .legend_color {
        width: 15px;
        height: 12px;
        margin-right: 6px;
      }
      &:first-child {
        .legend_color {
          background-color: ${Color.blueLightMedium};
        }
      }
      &:last-child {
        .legend_color {
          background-color: ${Color.blueDark};
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .chart {
      padding: 15px;
    }
  }
  @media (max-width: 800px) {
    .chart-wrapper {
      overflow-x: scroll;

      .chart {
        width: 700px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
  }
`;
