import React from "react";

import { Wrapper } from "./styled";
import * as Types from "./types";

const Modal: React.FC<Types.Props> = ({
  openModal,
  setOpenModal,
  closeOutsideClick = true,
  children,
  style={}
}) => {
  const modalRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickOutside = (event: any) => {
    if (!modalRef?.current?.contains(event.target) && openModal) {
      setOpenModal(false);
    }
  };

  React.useEffect(() => {
    if (closeOutsideClick) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }

    return undefined;
  });

  return (
    <Wrapper>
      <div style={style} ref={modalRef}>{children}</div>
    </Wrapper>
  );
};

export default Modal;
