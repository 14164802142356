import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import Input from "../Input";
import PosCategoryColumn from "./PosCategoryColumn";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as CheckCircle } from "../../assets/img/checkCircle.svg";
import DataTable from "react-data-table-component";
import InnerPosCategoryGroup from "./InnerPosCategoryGroup/InnerPosCategoryGroup";
import {
  IPosCategoryColumnWrapper,
  IScaleParameter,
  posCategoryItem,
  posCategoryItemRequest
} from "../../types/posRyles";
import {
  editCategoryAxios,
  removeCategoryAxios,
  setCategoryAxios
} from "../../api/posTerminals";
import Select from "../Select";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const PosCategoryColumnWrapper = ({ items, setItems,edit, setEdit, getAllCategories }: IPosCategoryColumnWrapper) => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  useEffect(()=>{
    setEdit(false)
  },[])
  
  const validInputTextValue = [
    '', 0, 0.0,'0'
  ]
  const saveItem = (id: number) => {
    const newItems = items[id];
    newItems.edit = false;
    if(validInputTextValue.includes(newItems.name) || validInputTextValue.includes(newItems.product_group)) {
      return
    }
    newItems.allocated = `${+newItems.scale_factor * +newItems.transaction_value}`;


    const requestItem:posCategoryItemRequest  = {
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      scale_parameter: `${newItems.scale_parameter}`,
      scale_factor: newItems.scale_factor,
      transaction_value: `${newItems.transaction_value}`,
      parent: ''
    }

    setCategoryAxios(requestItem).then(() => {
      getAllCategories();
      setEdit(false);
    }).catch(e => {
      console.log(e);
    });
    // setItems((items: Array<any>) => items = [...newItems]);
  };
  const editItem = (id: number) => {
    const newItems = items;
    newItems[id].edit = true;
    setItems((items: Array<any>) => items = [...newItems]);
    setEdit(true)
  };
  const saveEditItem = (id: number | undefined) => {
    const newItems = items.filter(item => item.id === id)[0];
    newItems.edit = true;
    if(validInputTextValue.includes(newItems.name) || validInputTextValue.includes(newItems.product_group)) {
      return
    }

    const requestItem:posCategoryItemRequest  = {
      id: newItems.id,
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      scale_parameter: `${newItems.scale_parameter}`,
      scale_factor: newItems.scale_factor,
      transaction_value: `${newItems.transaction_value}`,
      parent: ''
    }
    setEdit(false);
    editCategoryAxios(requestItem).then(()=>{
      getAllCategories();
    })

  };
  const removeItem = (id: number | undefined) => {
    removeCategoryAxios(`${id}`)
      .then((res) => {
        getAllCategories();
      }).catch(e => {
      console.log(e);
    });
    setItems((items: Array<any>) => items.filter((category, index) => index !== id));
  };

  const scaleParemeterSelectList:IScaleParameter[] = [
    { value: "price", label: "Price" },
    {value: "qty", label: "Quantity"}
  ];

  const categoryList = [
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            getValue={(value) => item.name = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_group}
            required
            getValue={(value) => item.product_group = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_sku}
            getValue={(value) => item.product_sku = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Select
            placeholder="Select Scale Parameter"
            selectorList={scaleParemeterSelectList}
            positionForShow="bottom"
            disabled={!item.edit}
            required
            defaultValue={item.scale_parameter  === 'price' ? { value: "price", label: "Price" } : {value: "qty", label: "Quantity"}}
            getValue={(value:string|number) =>
              item.scale_parameter = value
            }
            hideSelectorFilter
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.scale_factor ? item.scale_factor : '0'}
            type="number"
            getValue={(value: string) => item.scale_factor = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            type={item.edit ? "number" : undefined}
            defaultValue={item.edit ? `${parseFloat(item.transaction_value as string)}` : `${loggedUser.user.currency_symbol || '€'}${parseFloat(item.transaction_value as string)}`}
            getValue={(value: string) => item.transaction_value = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={`${+item.scale_factor * +item.transaction_value}`}
            disabled={true}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "3.5%",
      cell: (item: posCategoryItem, index: number): JSX.Element => {

        return (
          <div className="buttons">
            {item.edit && item.id ?
              <button className="btn-white edit-category" onClick={() => saveEditItem(item.id)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category" onClick={() => saveItem(index)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
              <span>Save</span>
            </button> :  <>
              <button disabled={edit} className="btn-white edit-category" onClick={() => editItem(index)}>
                      <span className="icon">
                        <Edit />
                      </span>
              </button>
              <button disabled={edit} className="btn-white remove-category" onClick={() => removeItem(item.id)}>
                      <span className="icon">
                      <Delete />
                      </span>
              </button>
            </>}

          </div>
        );
      }
    }
  ];


  return (
    <Wrapper>
      <div className="header">
        <div style={{width: '3%'}}></div>
        <PosCategoryColumn title={"Loyalty Category"} description={"Name your product category. e.g Fue"} />
        <PosCategoryColumn title={"Product Group"} description={"Enter your group I.D processed through your POS"} />
        <PosCategoryColumn title={"Product SKU"} description={"Enter your product SKU processed through your POS"} />
        <PosCategoryColumn title={"Scale Parameter"}
                           description={"Select the parameter to scale on fuel: (Price/Volume)"} />
        <PosCategoryColumn title={"Scale Factor"}
                           description={`Enter the scaling factor. e.g x 2 = double points, x 0.2 = ${loggedUser.user.currency_symbol || '€'}5 = 1 point`} />
        {/*<div className="column">*/}
        {/*  <h2 className="page-title">Loyalty Points issued for this group</h2>*/}
        {/*  <div className="column-group">*/}
            <PosCategoryColumn title={"Transaction Value"}
                               description={"Fuel enter transaction value (€/£) or volume (LTR)"} />
            <PosCategoryColumn title={"Points allocated"} description={""} />
        {/*  </div>*/}
        {/*</div>*/}
        <div className="column_buttons"></div>
      </div>
      <div className="category-list">

        {items !== null ? (
          items.length > 0 ? (
            <>
              {edit ? <div className="table table-edit">
                <DataTable
                  noTableHead
                  noHeader
                  columns={categoryList}
                  data={items}
                  expandableRows
                  expandableRowsComponent={
                    <InnerPosCategoryGroup data={items} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setItems} scaleParemeterSelectList={scaleParemeterSelectList}/>
                  }
                />
              </div> : <div className="table">
                <DataTable
                  noTableHead
                  noHeader
                  columns={categoryList}
                  data={items}
                  expandableRows
                  expandableRowsComponent={
                    <InnerPosCategoryGroup data={items} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setItems} scaleParemeterSelectList={scaleParemeterSelectList}/>
                  }
                />
              </div>}

            </>
          ) : (
            <div className="nothing-found">Sorry, nothing found</div>
          )
        ) : null}
      </div>
    </Wrapper>
  );
};

export default PosCategoryColumnWrapper;
