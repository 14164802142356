import axios from "./api";

export const getAllProductDiscountAxios = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/business/product/discount/get", requestParameters)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createProductDiscountAxios = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/business/product/discount/create", requestParameters)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeProductDiscountAxios = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/business/product/discount/remove", requestParameters)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
