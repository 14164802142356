import axios from "./api";

export const getAdminDashboardInfoAxios = (data: {
  date: number;
  locationId: number;
}): Promise<any> =>
  axios
    .post("/api/dashboard", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdminUsersAxios = (data:any): Promise<any> =>
  axios
    .post("/api/getCustomersList", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const updateAdminUserAxios = (data:any): Promise<any> =>
  axios
    .post("/api/admin/customer/update", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeAdminUserAxios = (data:any): Promise<any> =>
  axios
    .post("/api/admin/customer/remove", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdminBusinessesAxios = (data: any): Promise<any> =>
  axios
    .post("/api/getBusinessList", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdminBusinessesNamesAxios = (): Promise<any> =>
  axios
    .get("/api/getBusinessListAll")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdminOneBusinessAxios = (requestParameters: {id: number}): Promise<any> =>
  axios
    .post("/api/getBusinessListOne", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const updateAdminOneBusinessAxios = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/admin/business/update", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createAdvertPartnerAxios = (data: any): Promise<any> => {
  return axios
    .post("/api/addAdvertPartner", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
export const removeAdvertPartnerAxios = (id:number): Promise<any> =>
  axios
    .post('/api/deleteAdvertPartner', {id})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createAdvertOutletsAxios = (data: any): Promise<any> =>
  axios
    .post("/api/addAdvertOffers", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeAdvertOutletsAxios = (id:number): Promise<any> =>
  axios
    .post(`/api/AdvertOffer/remove`, {id})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getAdvertPartnersAxios = (): Promise<any> =>
  axios
    .get(`/api/getAdvertPartner`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const moveAdvertPartnersAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/AdvertPartner/set/position`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdvertOffersAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/getAdvertOffers`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const uploadPhotoAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/updatePhoto`, data, {headers: {
        'Content-Type': 'multipart/form-data'
      }
    } )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAllUserForBusinessPlaceAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/admin/location/customers`, data )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAllUserTransactionForBusinessPlaceAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/admin/location/customer/transactions`, data )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAllUserTransactionBasketForBusinessPlaceAxios = (data: any): Promise<any> =>
  axios
    .post(`/api/admin/location/customer/transaction/basket`, data )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerInfoAdminAxios = (user_id: string, search: string, searchType: string, location: string, startDate: any, endDate: any): Promise<any> =>
  axios
    .post("/api/admin/customers/get", { user: user_id, search: search, searchType: searchType, location: location, startDate: startDate, endDate: endDate })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

