import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
        display: flex;
        flex-direction: column;

    .logo_wrap {
        background-color: #000d46;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 70px;

    }

    img {
        height: 100%;
        object-fit: cover;
    }

    .form {
        display: flex;
        padding: 20px;
        justify-content: flex-start;
        align-items: flex-end;

    }

    .barcodeInput {
        width: 100%;
        max-width: 400px;
        border: none;
        padding: 10px 16px;
        height: 45px;
        font-size: 18px;
        line-height: 20px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    }

    .label {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #000d46;
    }

    .submitBtn {
        margin-left: 20px;
        width: auto;
        height: 49px;
        border-radius: 4px;
        background: linear-gradient(
                0deg,
                #515CDDFF -16.46%,
                #3FB1FFFF 98.11%
        );
        border: 1px solid #0D6EFDFF;
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 14px;
        font-weight: 500;
        line-height: 23px;
        text-align: center;
        padding: 10px;
        transition: 0.15s all ease;

        &:hover {
            box-shadow: #0D6EFDFF 0px 0px 10px 0px;
        }

        &:disabled, &[disabled] {
            opacity: 0.5;
            cursor: auto;
            box-shadow: none;
        }
    }
`;

export { Wrapper };
