import React, { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { ReactComponent as VisibilityIcon } from "src/assets/img/visibility.svg";
import { WrapperTableList } from "./styled";
import { getStaffDiscountActivity} from "src/api/customers";
import {format} from "date-fns";
import TransactionInformation from "../../Customers/CustomerList/TransactionInformation/TransactionInformation";

interface IProps {
  data: any;
  getCustomerList: any;
}

const StaffFamilyTable: FC<IProps> = ({ data, getCustomerList }) => {
  const [userInfo, setUserInfo] = useState([]);
  const getUserPointInfo = (id: string) => {
    getStaffDiscountActivity({ id }).then((response) => {
      setUserInfo(response.data.activities);
    });
  };
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});

  useEffect(() => {
    getUserPointInfo(data.id);
  }, [data.id]);

  const staffMembersTableColumns = [
    {
      name: "Full Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.full_name}
          </div>
        );
      }
    },
    {
      name: "POS.I.D",
      cell: (row: any): JSX.Element => {
        return <div>{row.pos_id || "-"}</div>;
      }
    },
    {
      name: "POS User .I.D",
      cell: (row: any): JSX.Element => {
        return <div>{row.pos_user_id || "-"}</div>;
      }
    },
    // {
    //   name: "Receipt No.",
    //   cell: (row: any): JSX.Element => {
    //     return <div>{row.receipt || "-"}</div>;
    //   }
    // },
    {
      name: "Time",
      minWidth: "120px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.time
              ? format(new Date(row.time*1000), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    },
    {
      name: "Basket",
      maxWidth: "75px",
      right: true,
      cell: (row: any, i: number): JSX.Element => {
        return (
          <>
            {row.basket ? (<div className={"show-order"} data-tag="allowRowEvents">
              <VisibilityIcon data-tag="allowRowEvents" className={"visibility-icon"} />
            </div>) : null}
          
          </>
        );
      }
    }
  ];

  return (
    <WrapperTableList>
      <DataTable data={userInfo} columns={staffMembersTableColumns} noHeader onRowClicked={(row: any) => {
        setShowTransactionDetail(true);
        row.basket && setCustomerInformation(row.basket);
        setCustomer(row);
      }}
                 expandableRows
                 expandableRowsHideExpander={true}
                 expandableRowExpanded={(row) => (row === customer)}
                 expandOnRowClicked={true}
                 onRowExpandToggled={(bool, row) => setCustomer(row)}
                 expandableRowsComponent={
                   <TransactionInformation staffPage={true} show={showTransactionDetail} customerInformation={customerInformation}
                                           productList={customerInformation.productsList} customer={customer}
                                           setShow={setShowTransactionDetail} />
                 }/>
    </WrapperTableList>
  );
};

export default StaffFamilyTable;
