import React from "react";

import userDefaultImg from "src/assets/img/default-user.jpg";
import { ReactComponent as NotificationIcon } from "../../assets/img/notification.svg";
import { ReactComponent as Arrow } from "../../assets/img/cardArrow.svg";

import { AccountInformationInterface } from "../../types/registration";

import { Wrapper } from "./styled";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const Card: React.FC<{
  acceptedFiles: File[] | string;
  accountInformation: AccountInformationInterface;
  cardClosed: boolean;
  setCardClosed: (cardClosed: boolean) => any;
  points_info?: {
    points?: string | number;
    maxPoints?: string | number;
    reward?: string;
  };
}> = ({
  acceptedFiles,
  accountInformation,
  cardClosed,
  setCardClosed,
  points_info,
}) => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  return (
    <Wrapper>
      {/* <div className={`card-preview ${cardType} ${businessInfo.category}`}> */}
      <div className="top">
        <div className="logo">
          {acceptedFiles.length ? (
            <img
              src={
                typeof acceptedFiles === "string"
                  ? acceptedFiles
                  : URL.createObjectURL(acceptedFiles[0])
              }
              alt=""
            />
          ) : (
            <img src={userDefaultImg} alt="" />
          )}
        </div>
        <div className="right">
          <div className="name-notificationCount">
            <div className="name">
              {accountInformation.companyName
                ? accountInformation.companyName
                : "Company Name"}
            </div>
            <div className="notification-count">
              {accountInformation.promotionType === 1 ? (
                <div className="notification">
                  <NotificationIcon />
                </div>
              ) : (
                <div className="reward_level">1</div>
              )}
              {/* <div className="count">
      {accountInformation.maxNumberPromotion}/
      {accountInformation.maxNumberPromotion}
     </div> */}
            </div>
          </div>
          <div className="info">
            <span className="title">
              {/* {accountInformation.promotionType === 1
                ? "Promotion:"
                : "Digital Loyalty Program:"} */}
              Digital Loyalty Program
            </span>
            <span className="content">
              {accountInformation.promotionType === 1
                ? // ? accountInformation.promotionLabel
                  "Stamps"
                : "Points"}
            </span>
          </div>
          <div className="info">
            <span className="title">Details:</span>
            <span className="content">
              {/* {accountInformation.promotionType === 1
                ? accountInformation.rewardInformation !== ""
                  ? accountInformation.rewardInformation
                  : null
                : `Earn ${points_info?.maxPoints ?? ""} points for ${
                    points_info?.reward ?? ""
                  }`} */}
              {accountInformation.rewardInformation ?? ""}
            </span>
          </div>
        </div>
      </div>
      <div className={`card-closed ${cardClosed ? "card-opened" : ""}`}>
        {accountInformation.promotionType === 2 ? (
          <div className="progress_wrapper">
            <div className="progress_line_wrapper">
              <p className="progress_count">
                {`${points_info?.points ?? ""} points out of ${
                  points_info?.maxPoints ?? ""
                }`}
              </p>
              <progress
                className="progress_indicator"
                max={points_info?.maxPoints}
                value={points_info?.points}
              />
            </div>
            <div className="progress_reward">
              <p className="progress_reward_off">{loggedUser?.user?.currency_symbol || '€'}10 OFF</p>
              <p className="progress_reward_one">Reward One</p>
            </div>
          </div>
        ) : accountInformation.stampName ? (
          <div className="promotion-count">
            {accountInformation.promotionType === 1 ? (
              <>
                {Array.from(
                  { length: accountInformation.maxNumberPromotion },
                  (_, i) => (
                    <div key={i} className="promotion-item">
                      <img
                        src={`/card-icons/${i + 1}_${
                          accountInformation.stampName
                        }_${i > 2 ? "grey" : "gradient"}.svg`}
                        alt={accountInformation.stampName}
                      />
                    </div>
                  )
                )}
                <div className="promotion_done">
                  <img
                    src={`/card-icons/${accountInformation.stampName}_free.svg`}
                    alt={accountInformation.stampName}
                  />
                </div>
              </>
            ) : // <>
            //   <div className="points-progress-line">
            //     <div className="progress-line">
            //       {`${accountInformation.maxNumberPromotion} points away`}
            //     </div>
            //     <div className="progress-done">
            //       <DiscountVoucherIcon />
            //       <span className="text">
            /* {businessInfo.promotionVaucherInformation} */
            //       </span>
            //     </div>
            //   </div>
            // </>
            null}
          </div>
        ) : null}
      </div>
      <div
        className={`card-button-closed ${
          cardClosed ? "card-button-opened" : ""
        }`}
      >
        <div className="card-bottom">
          <div className="bottom-top" />
          <button
            onClick={() => {
              setCardClosed(!cardClosed);
            }}
            type="button"
            className="card-bottom-control-button"
          >
            <Arrow />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Card;
