import React, { FC } from "react";

interface Props {
  checked: boolean;
  toggleChecked: () => void;
}

const СheckboxTable: FC<Props> = ({ checked, toggleChecked }) => {
  return (
    <label htmlFor="user_all" className="checkbox">
      <input
        type="checkbox"
        id="user_all"
        checked={checked}
        onChange={() => toggleChecked()}
      />
      <span className="checkbox-view" />
    </label>
  );
};

export default СheckboxTable;
