import React, { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import { GOOGLE_API_KEY } from "src/types/env";

import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { ReactComponent as PlacePointIcon } from "src/assets/img/place-point.svg";

import { Wrapper } from "./styled";
import * as Type from "./types";

const InputGoogleAutocomplete: React.FC<Type.Props> = ({
  label,
  placeholder,
  infoTooltip,
  defaultValue,
  required,
  disabled,
  getValue,
  getLat,
  getLng,
  onFocus,
}) => {
  const [value, setValue] = React.useState<string>(defaultValue || "");

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]); //eslint-disable-line

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      setValue(place.formatted_address);
      if (getValue) getValue(place.formatted_address);
      if (getLat) getLat(place.geometry.location.lat());
      if (getLng) getLng(place.geometry.location.lng());
    },
    options: {
      types: ["geocode"],
      language: "en",
    },
  });

  const handleChange = (currentValue: string) => {
    setValue(currentValue);
  };

  return (
    <Wrapper required={required} isLabel={!!label}>
      <span className="label">{label}</span>
      {infoTooltip && (
        <span className="info-tooltip">
          <InfoIcon />
          <span className="tooltip">{infoTooltip}</span>
        </span>
      )}
      <input
        ref={ref}
        type="text"
        className=""
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(event) => {
          return handleChange(event.target.value);
        }}
        onFocus={() => {
          if (onFocus) onFocus();
        }}
      />
      <span className="place-point-icon">
        <PlacePointIcon />
      </span>
    </Wrapper>
  );
};

export default InputGoogleAutocomplete;
